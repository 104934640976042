import React from "react"
import ButtonElement from "../atoms/ButtonElement"
import Flex from "../atoms/Flex"
import PopUpMenu from "../atoms/PopUpMenu"
import { Text } from "../atoms/Typography"
import Box from "../atoms/Box"
import Card from "../atoms/Card"
import Caret from "../icons/Caret"

const FilterDropdown = ({
  menuOpen,
  options,
  anchorRef,
  name,
  id,
  showFilterLabel,
  location,
  selected,
  alignMenuItems,
  allLabel,
  dropdownCard,
  setMenuOpen,
  setSelected,
  ...rest
}) => {
  const handleSelectedDropdownStyle = () => {
    return {
      color: "text",
      fontSize: 1,
      overflow: "hidden",
      textOverflow: "ellipsis",
      maxWidth: "104px",
      whiteSpace: "nowrap",
      display: "inline-block",
      mr: 4,
    }
  }
  return (
    <>
      {showFilterLabel && (
        <Box mb={4}>
          <Text fontSize={1} color="text">
            {name}
          </Text>
        </Box>
      )}
      <Card {...dropdownCard}>
        <ButtonElement
          aria-label="Filter Button"
          ref={anchorRef}
          onClick={() => setMenuOpen(true)}
          pt={4}
          pl={4}
          pb={4}
          pr={5}
          {...rest}
          mr={0}
        >
          <Flex minWidth={8} alignItems="center" justifyContent="space-between">
            <Text {...handleSelectedDropdownStyle()}>
              {selected?.[id] || allLabel}
            </Text>
            <Caret color="text" />
          </Flex>
        </ButtonElement>
        <PopUpMenu
          location={location}
          anchorEl={anchorRef.current}
          show={menuOpen}
          active={selected?.[allLabel]}
          onSelect={value => {
            setSelected({ [id]: value })
          }}
          handleClose={() => setMenuOpen(false)}
          options={options}
          card={dropdownCard}
          alignMenuItems={alignMenuItems}
          menuListMaxHeight="400px"
        />
      </Card>
    </>
  )
}

export default FilterDropdown
