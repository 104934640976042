import React, { useState, useRef, useContext } from "react"
import { navigate, notGatsby } from "gatsby"
import { useFormik } from "formik"
import { useAlert } from "react-alert"
import PropTypes from "prop-types"
import { get } from "lodash"

import {
  processInputs,
  getInitialValues,
  getValidationSchema,
} from "../../lib/formik"

import { useSitePages } from "../../hooks/useSitePagesData"
import { getPagePath } from "../../utils/page"
import { getRedirectURL } from "../../utils/utils"
import { PopupsContext } from "../../contexts/PopupsContext"

import TextField from "../atoms/TextField"
import Select from "../atoms/Select"
import Captcha from "../atoms/Captcha"
import Button from "../atoms/Button"

export const captchaValidation = (values, recaptchaRef) => {
  const errors = {}
  if (values["g-recaptcha-response"]?.length > 0) return errors

  if (recaptchaRef?.current?.props.size === "invisible") {
    recaptchaRef.current.execute()
    return errors
  }

  errors["g-recaptcha-response"] = "Please complete the captcha to proceed" //show this message when recaptchaCode is null

  if (
    typeof values["g-recaptcha-response"] === "string" ||
    values["g-recaptcha-response"] === null
  ) {
    if (recaptchaRef?.current?.props.sitekey === "no_sitekey") {
      errors["g-recaptcha-response"] =
        "Captcha is not setup properly. Please check integration settings"
    } else {
      errors["g-recaptcha-response"] = "Please check the checkbox"
    }
  }
  return errors
}

const useRedirectToSuccessURL = successURLRedirect => {
  const { to, href, sectionId } = successURLRedirect ?? {}
  const sitePages = useSitePages()
  const isLinkAPopup = sectionId?.includes("popups")
  const { openModalSectionById } = useContext(PopupsContext)

  const redirectToURL = () => {
    if (isLinkAPopup && openModalSectionById) {
      openModalSectionById(sectionId)
    } else if (to) {
      let pagePath = getPagePath({ to, sitePages, sectionId })
      navigate(pagePath)
    } else if (href) {
      window.location.href = getRedirectURL(href)
    }
  }

  return redirectToURL // return a function to be called in onSubmit
}

const MorphicForm = ({ label, data, captcha, successURLRedirect }) => {
  const alert = useAlert()
  const [loading, setLoading] = useState(false)
  const recaptchaRef = useRef(null)
  const isNetlify = !data.submit?.url

  const captchaField = {
    name: "g-recaptcha-response",
    type: "recaptcha",
    default: "",
  }
  const updatedInputs = captcha?.useCaptcha
    ? [...data.inputs, captchaField]
    : data.inputs

  const inputs = processInputs(updatedInputs)

  const redirectToSuccess = useRedirectToSuccessURL(successURLRedirect)

  const formik = useFormik({
    initialValues: getInitialValues(inputs),
    validationSchema: getValidationSchema(inputs),
    onSubmit: async (values, actions) => {
      if (notGatsby) return

      try {
        if (captcha.useCaptcha && recaptchaRef?.current?.getValue() === "") {
          // clearing captcha code upon submitting the form
          actions.setFieldValue("g-recaptcha-response", "")
        } else {
          setLoading(true)

          let url, body, headers

          if (isNetlify) {
            url = "/"
            values["form-name"] = label
            body = new URLSearchParams(values)
            headers = { "Content-Type": "application/x-www-form-urlencoded" }
          } else {
            url = data.submit?.url
            body = JSON.stringify({
              ...values,
              provider: captcha.useCaptcha ? data.provider : null,
              integrationId: data.integrationId,
            })
            headers = { "Content-Type": "application/json" }
          }

          let res = await fetch(url, {
            method: "POST",
            headers,
            body,
          })

          if (!res.ok) {
            alert.show("There was an error submitting the form", {
              type: "error",
            })
          } else {
            alert.show("Form submitted successfully!", { type: "success" })
            actions.resetForm({ values: "" })
            if (captcha.useCaptcha) {
              recaptchaRef?.current?.reset()
            }
            redirectToSuccess() // this will redirect to a success URL after form submission when successURLRedirect is set
          }
        }
        setLoading(false)
      } catch (err) {
        console.log(err)
        alert.show(err, { type: "error" })
        setLoading(false)
      }
    },
    validate: values => {
      //validate recaptcha field if it's included in the form fields
      let captchaError = {}
      return !notGatsby && captcha.useCaptcha
        ? captchaValidation(values, recaptchaRef)
        : captchaError
    },
  })

  const formInputs = inputs.map((input, i) => {
    const name = input.name
    const touched = get(formik.touched, name)
    const error = get(formik.errors, name)
    const formikProps = {
      name: name,
      value: get(formik.values, name, ""),
      onChange: formik.handleChange,
      onBlur: formik.handleBlur,
      error: touched && Boolean(error),
      helperText: touched && error,
    }
    switch (input.type) {
      case "text":
      case "email":
      case "url":
      case "multiline":
        return (
          <TextField
            id={name}
            label={input.label}
            largeLabelSize={true}
            required={input.required}
            multiline={input.type === "multiline"}
            rows={input.type === "multiline" ? 4 : undefined}
            width="100%"
            mb={4}
            key={i}
            {...formikProps}
          />
        )
      case "select":
        return (
          <Select
            id={name}
            label={input.label}
            largeLabelSize={true}
            options={input.options}
            required={input.required}
            width="100%"
            mb={4}
            key={i}
            {...formikProps}
          />
        )
      case "recaptcha":
        return (
          <Captcha
            ref={recaptchaRef}
            id={name}
            key={i}
            label={input.label}
            options={input.options}
            required={input.required}
            width="100%"
            controlType="recaptcha"
            {...captcha}
            {...formikProps}
            onChange={value => {
              formik.setFieldValue("g-recaptcha-response", value)
            }}
          />
        )
      default:
        return null
    }
  })

  // Form with action URL
  if (!isNetlify) {
    return (
      <form onSubmit={formik.handleSubmit} target="_blank">
        {formInputs}
        <Button
          aria-label="Submit form button"
          type="submit"
          loading={loading}
          variant="primary"
        >
          Submit
        </Button>
      </form>
    )
  }

  // Netlify Form
  const hasRecatpcha = captcha.useCaptcha
    ? { "data-netlify-recaptcha": "true" }
    : {}

  return (
    <form
      onSubmit={formik.handleSubmit}
      data-netlify={isNetlify ? "true" : "false"}
      netlify-honeypot="bot-field"
      name={label}
      {...hasRecatpcha}
    >
      <input type="hidden" name="bot-field" />
      <input type="hidden" name="form-name" value={label} />
      {formInputs}
      <Button
        aria-label="Submit form button"
        type="submit"
        loading={loading}
        variant="primary"
      >
        Submit
      </Button>
    </form>
  )
}

MorphicForm.propTypes = {
  label: PropTypes.string,
  data: PropTypes.object,
}

export default MorphicForm
