import React, { useRef, useState, useContext, useEffect } from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import styled, { ThemeContext } from "styled-components"

import Reveal from "../utils/Reveal"

import Card from "../atoms/Card"
import Section from "../atoms/Section"
import FlexGrid from "../atoms/FlexGrid"
import Filter from "../atoms/Filter"
import Box from "../atoms/Box"
import Flex from "../atoms/Flex"

import FilteredGrid from "../molecules/FilteredGrid"
import TeamCard from "../molecules/TeamCard"
import SectionTitle from "../molecules/SectionTitle"
import SearchGrid from "../molecules/SearchGrid"
import AlphabeticalFilter from "../molecules/AlphabeticalFilter"

const CarouselTrack = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  flex-wrap: nowrap;
  left: 0;
  align-items: center;
  scrollbar-width: none;

  /* Hide scrollbar for Chrome, Safari and Opera */
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`

const TeamGrid = ({
  section,
  sectionTitle,
  TGSectionTitle,
  flexGrid,
  TGFlexGrid,
  displayMode = "grid",
  carouselHeight = 200,
  carouselWidth = "normal",
  carouselSpacing = 12,
  card,
  TGCard,
  teamCards,
  imageShape,
  showNameOnHover,
  zoomImageOnHover,
  align,
  filter,
  TGFilter,
  filters,
  TGFilters,
  teamGridImageRatio,
  imageRatio,
  teamGridImageWidth,
  imageWidth,
  sort,
  TGSort,
  filterSortLocation,
  searchGrid,
  TGSearchGrid,
  alphabeticalFilter,
  gridInitialView,
  useSearchFilter,
  useAlphabeticalFilter,
}) => {
  const currentTheme = useContext(ThemeContext)
  const [searchTerm, setSearchTerm] = useState("")
  const [selectedAlphaLetterFilter, setSelectedAlphaLetterFilter] = useState("")

  const filterPriority = searchGrid?.filterPriority ?? "searchAndFilters"

  const handleSearch = e => {
    const searchValue = e.target.value
    setSearchTerm(searchValue)
  }

  useEffect(() => {
    if (filterPriority === "searchOnly" && searchTerm.length > 0) {
      setSelectedAlphaLetterFilter("")
    }
  }, [searchTerm, filterPriority])

  sectionTitle = TGSectionTitle || sectionTitle
  flexGrid = TGFlexGrid || flexGrid
  card = TGCard || card
  filters = TGFilters || filters
  filter = TGFilter || filter
  sort = TGSort || sort
  imageRatio = teamGridImageRatio || imageRatio
  imageWidth = teamGridImageWidth || imageWidth
  displayMode = displayMode || "grid"
  carouselWidth = carouselWidth || "normal"
  carouselHeight = carouselHeight || 200
  carouselSpacing = carouselSpacing || 12
  searchGrid = { ...(TGSearchGrid || searchGrid), useSearch: useSearchFilter }
  alphabeticalFilter = {
    ...alphabeticalFilter,
    useFilter: useAlphabeticalFilter,
  }
  const isCarousel = displayMode === "carousel"

  const getImageRatio = imageRatio => {
    switch (imageRatio) {
      case "sixteenNine":
        return 0.5625
      case "threeTwo":
        return 0.66
      case "fourThree":
        return 0.75
      case "oneOne":
        return 1
      case "threeFour":
        return 1.3333
      case "twoThree":
        return 1.5
      default:
        return 0.75
    }
  }

  // Click and drag functionality for carousel
  const carouselRef = useRef()
  let mouseDown = false
  let startX, scrollLeft

  const startDrag = e => {
    mouseDown = true
    startX = e.pageX - carouselRef.current.offsetLeft
    scrollLeft = carouselRef.current.scrollLeft
  }

  const endDrag = e => {
    mouseDown = false
  }

  const drag = e => {
    e.preventDefault()
    if (!mouseDown) {
      return
    }
    const x = e.pageX - carouselRef.current.offsetLeft
    const scroll = x - startX
    carouselRef.current.scrollLeft = scrollLeft - scroll
  }

  return (
    <Section {...section}>
      {sectionTitle && (
        <Reveal>
          <SectionTitle {...sectionTitle} mb={6} maxWidth={42} />
        </Reveal>
      )}
      {searchGrid?.useSearch && (
        <SearchGrid
          searchTerm={searchTerm}
          handleSearch={handleSearch}
          alignment={searchGrid?.alignSearchBar}
          placeholder={searchGrid?.defaultSearchBarText || "Search"}
          themeColors={{
            themes: currentTheme?.themes,
            sectionTheme: section?.theme,
          }}
        />
      )}

      {alphabeticalFilter?.useFilter && (
        <AlphabeticalFilter
          selectedAlphaLetterFilter={selectedAlphaLetterFilter}
          setSelectedAlphaLetterFilter={setSelectedAlphaLetterFilter}
          marginBottom={filter?.layout === "tabs" ? 5 : 7}
        />
      )}
      {isCarousel && (
        <Box
          position="relative"
          left={0}
          ml={
            carouselWidth === "fullWidth"
              ? {
                  _: "-24px",
                  lg: "-48px",
                  xl: "calc(50% - 50vw)",
                }
              : null
          }
          mr={
            carouselWidth === "fullWidth"
              ? {
                  _: "-24px",
                  lg: "-48px",
                  xl: "calc(50% - 50vw)",
                }
              : null
          }
        >
          <CarouselTrack
            ref={carouselRef}
            onMouseDown={startDrag}
            onMouseMove={drag}
            onMouseUp={endDrag}
            onMouseLeave={endDrag}
          >
            <Flex minWidth="fit-content" py="50px">
              {teamCards?.map((person, i) => (
                <Box
                  key={i}
                  position="relative"
                  height="fit-content"
                  width={carouselHeight / getImageRatio(imageRatio)}
                  mr={
                    i === teamCards?.length - 1 && carouselWidth === "fullWidth"
                      ? {
                          _: "24px",
                          lg: "48px",
                          xl: `calc(50vw - ${section?.maxWidth / 2 - 16}px)`,
                        }
                      : i === teamCards?.length - 1 &&
                        carouselWidth !== "fullWidth"
                      ? null
                      : `${carouselSpacing}px`
                  }
                  ml={
                    i === 0 && carouselWidth === "fullWidth"
                      ? {
                          _: "24px",
                          lg: "48px",
                          xl: `calc(50vw - ${section?.maxWidth / 2 - 16}px)`,
                        }
                      : null
                  }
                >
                  <TeamCard
                    key={i}
                    {...person}
                    card={card}
                    align={align}
                    imageShape={imageShape}
                    showNameOnHover={showNameOnHover}
                    zoomImageOnHover={zoomImageOnHover}
                    imageRatio={imageRatio}
                    imageWidth={imageWidth}
                  />
                </Box>
              ))}
            </Flex>
          </CarouselTrack>
        </Box>
      )}
      {!isCarousel && (
        <FilteredGrid
          flexGrid={flexGrid}
          filters={filters}
          filtersStyles={{
            filterLocation: filter?.filterLocation,
            alignMenuItems: filter?.alignMenuItems,
            showFilterLabel: filter?.showFilterLabel,
            layout: filter?.layout,
            dropdownCard: filter?.dropdownCard,
          }} // only sending style fields to avoid possibly overwriting filter data
          card={{
            theme: section.theme,
            bg: section.bg,
            borderRadius: "md",
            // boxShadow: "md",
            boxShadow: "0 3px 10px rgb(0 0 0 / 30%)",
          }}
          sort={{
            ...sort,
            optionsObj: {
              customOrderBool: sort?.customOrderTitle || "Custom Order",
              optionOneBool: "First Name",
              optionTwoBool: "Last Name",
              optionThreeBool: "Position Title",
            },
          }}
          filterSortLocation={filterSortLocation}
          searchedTerm={searchTerm}
          selectedAlphaLetterFilter={selectedAlphaLetterFilter}
          alphabeticalFilter={alphabeticalFilter}
          searchProps={{
            ...searchGrid,
            optionOneBoolField: "name",
            optionTwoBoolField: "positionTitle",
          }}
          gridInitialView={gridInitialView}
        >
          {teamCards &&
            teamCards.map((person, i) => (
              <TeamCard
                key={i}
                {...person}
                card={card}
                align={align}
                imageShape={imageShape}
                showNameOnHover={showNameOnHover}
                zoomImageOnHover={zoomImageOnHover}
                imageRatio={imageRatio}
                imageWidth={imageWidth}
              />
            ))}
        </FilteredGrid>
      )}
    </Section>
  )
}

export default TeamGrid

TeamGrid.strapiProps = {
  strapi_id: PropTypes.number.isRequired,
  section: PropTypes.shape(Section.strapiProps).isRequired,
  sectionTitle: PropTypes.shape(SectionTitle.strapiProps).isRequired,
  flexGrid: PropTypes.shape(FlexGrid.strapiProps),
  imageShape: PropTypes.oneOf(["default", "square", "circle"]),
  showNameOnHover: PropTypes.bool,
  zoomImageOnHover: PropTypes.bool,
  useSearchFilter: PropTypes.bool,
  useAlphabeticalFilter: PropTypes.bool,
  alphabeticalFilter: PropTypes.shape(AlphabeticalFilter.strapiProps),
  gridInitialView: PropTypes.oneOf(["allItems", "empty"]),
  align: PropTypes.oneOf(["left", "center"]),
  filters: PropTypes.arrayOf(PropTypes.shape(Filter.strapiProps)),
  filter: PropTypes.shape(Filter.strapiProps),
  card: PropTypes.shape(Card.strapiProps),
  teamCards: PropTypes.arrayOf(PropTypes.shape(TeamCard.strapiProps)),
}

TeamGrid.propTypes = {
  ...TeamGrid.strapiProps,
}

export const query = graphql`
  fragment TeamGrid on STRAPI__COMPONENT_SECTIONS_TEAM_GRID {
    strapi_id
    TEAMGRIDSection: section {
      ...Section
    }
    TGSectionTitle: sectionTitle {
      ...SectionTitle
    }
    TGFlexGrid: flexGrid {
      ...FlexGrid
    }
    displayMode
    carouselHeight
    carouselWidth
    carouselSpacing
    TGCard: card {
      ...Card
    }
    teamCards {
      ...TeamCard
    }
    TGFilters: filters {
      ...Filter
    }
    TGFilter: filter {
      alignMenuItems
      filterLocation
      showFilterLabel
      layout
      dropdownCard {
        ...Card
      }
    }
    imageShape
    showNameOnHover
    zoomImageOnHover
    align
    teamGridImageRatio: imageRatio
    teamGridImageWidth: imageWidth
    TGSort: sort {
      ...Sort
    }
    TGSearchGrid: searchGrid {
      ...SearchGrid
    }
    filterSortLocation
    alphabeticalFilter {
      ...AlphabeticalFilter
    }
    gridInitialView
    useSearchFilter
    useAlphabeticalFilter
  }
`
