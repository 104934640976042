import React, { useEffect, useState } from "react"
import { notGatsby, graphql } from "gatsby"
import { get } from "lodash"
import { space, compose } from "styled-system"
import styled from "styled-components"
import FormHelperText from "@material-ui/core/FormHelperText"
import FormControl from "@material-ui/core/FormControl"
import MuiFormControlLabel from "@material-ui/core/FormControlLabel"
import ReCAPTCHA from "react-google-recaptcha"

import { shouldForwardProp } from "../../lib/styled-system/helpers.js"

export const getFormikProps = (formik, name) => {
  if (!formik) return { name }

  const touched = get(formik.touched, name)
  const error = get(formik.errors, name)
  return {
    name: name,
    value: get(formik.values, name),
    onChange: formik.handleChange,
    error: touched && Boolean(error),
    helperText: touched && error,
    onBlur: formik.handleBlur,
  }
}
const StylesFormHelperText = styled(FormHelperText)`
  ${compose(space)}
  &.MuiFormHelperText-root {
    margin-top: -10px;
    margin-bottom: 10px;
  }
`

const StylesFormControl = styled(FormControl).withConfig({
  shouldForwardProp,
  displayName: "StylesFormControl",
})`
  ${compose(space)}
  & .MuiFormControl-root {
  }

  & .MuiFormControlLabel-root {
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 14px;
  }
`

const defaultCaptcha = {
  useCaptcha: true,
  size: "normal", //normal, invisible or compact
  theme: "dark", //light, dark
  // badge =bottomright {bottomright, bottomleft or inline}. Positions reCAPTCHA badge. Only for invisible reCAPTCHA
}

// TODO: Monospaced font for code input?
const Captcha = React.forwardRef(
  (
    {
      id,
      name,
      label,
      value,
      error,
      helperText,
      onChange,
      checkedIcon,
      useCaptcha,
      badge,
      theme,
      size,
      disabled,
      formik,
      controlType = "recaptcha",
      ...rest
    },
    ref
  ) => {
    const captcha = {}
    captcha.useCaptcha = useCaptcha ? useCaptcha : defaultCaptcha.useCaptcha
    captcha.badge =
      size !== "invisible" ? null : !badge ? defaultCaptcha.badge : badge
    captcha.theme = theme ? theme : defaultCaptcha.theme
    captcha.size = size ? size : defaultCaptcha.size

    const [key, setKey] = useState(0)
    useEffect(() => {
      setKey(key + 1)
    }, [badge, theme, size])

    if (
      !captcha.useCaptcha ||
      (!process.env.GATSBY_SITE_RECAPTCHA_KEY &&
        !process.env.NEXT_PUBLIC_GR_SITE_KEY)
    ) {
      return
    }

    let captchaKey = "no_sitekey"

    const formikProps = getFormikProps(formik, name)
    const {
      error: formikError,
      helperText: formikHelperText,
      ...otherFormikProps
    } = formikProps

    const control =
      controlType === "hCaptcha" ? null : controlType === "recaptcha" ? (
        <ReCAPTCHA
          ref={ref}
          sitekey={
            process.env.NEXT_PUBLIC_GR_SITE_KEY
              ? process.env.NEXT_PUBLIC_GR_SITE_KEY
              : process.env.GATSBY_SITE_RECAPTCHA_KEY || captchaKey
          }
          id={id}
          name={name}
          value={value}
          checked={(formik && formik.values[name]) || value}
          onChange={onChange}
          onExpired={() => onChange("")} //resetting captcha field to empty string instead of null, need to check if this works
          disabled={disabled}
          checkedIcon={checkedIcon}
          {...captcha}
          {...otherFormikProps}
          {...rest}
          //target className="rc-anchor-container" to change background color
        />
      ) : (
        <div></div>
      )

    return (
      <StylesFormControl
        {...rest}
        error={(formik && Boolean(formik?.errors[name])) || error}
      >
        <MuiFormControlLabel key={key} control={control} label={label} />

        {(error || Boolean(formik?.errors[name])) && (
          <StylesFormHelperText mb={4} id={`${id}-helper-text`}>
            {helperText || formikHelperText}
          </StylesFormHelperText>
        )}
      </StylesFormControl>
    )
  }
)

export default Captcha

export const query = graphql`
  fragment Captcha on STRAPI__COMPONENT_ATOMS_CAPTCHA {
    useCaptcha
    badge
    theme
    size
  }
`
