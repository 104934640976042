import axios from "axios"
const LEVER_JOBS_API = "https://api.lever.co/v0/postings"

export const fetchLeverApiJobs = async companyName => {
  try {
    let res = await axios.get(
      `${LEVER_JOBS_API}/${companyName}?group=department&mode=json`
    )
    const sortedData = res.data.map(category => {
      category.postings.sort((a, b) => {
        if (a?.categories?.team === b?.categories?.team) {
          return a?.text.localeCompare(b?.text)
        } else {
          return a?.categories?.team.localeCompare(b?.categories?.team)
        }
      })
      return category
    })
    res.data = sortedData
    return res
  } catch (error) {
    const integrationError =
      "Failed to fetch data. Please check that your integration settings are correct, and Make sure your Lever account has API access enabled."
    error.message =
      error?.response?.status === 404 ? integrationError : error.message
    // console.log({ error })
    return error
  }
}

export default fetchLeverApiJobs
