import React from "react"
import PropTypes from "prop-types"

import Path from "../utils/Path"

const Arrow = ({ disabled, onClick, left }) => {
  return (
    <button
      style={{
        border: "none",
        background: "none",
        padding: "0",
        margin: "0",
        height: "auto",
        width: "auto",
        opacity: disabled ? 0.25 : 0.65,
        transform: left ? "rotate(180deg)" : "",
        display: "flex",
        alignItems: "center",
      }}
      onClick={onClick && !disabled ? onClick : undefined}
    >
      <svg
        height="34"
        viewBox="0 0 23 34"
        width="23"
        xmlns="http://www.w3.org/2000/svg"
      >
        <Path
          d="m1342 2208v8l-16-.001v16.001h-8v-24z"
          fill="primary"
          fillRule="evenodd"
          opacity=".65"
          transform="matrix(-.70710678 .70710678 -.70710678 -.70710678 2516.229073 646.325035)"
        />
      </svg>
    </button>
  )
}

Arrow.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  left: PropTypes.bool,
}

export default Arrow
