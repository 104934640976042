import React from "react"
import Svg from "../utils/Svg"
import G from "../utils/SvgGroup"

const Checkmark = ({ color, ...rest }) => {
  return (
    <Svg
      width="11px"
      height="11px"
      viewBox="0 0 11 11"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="2"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="square"
      >
        <G
          id="Pricing"
          transform="translate(-270.000000, -449.000000)"
          stroke={color || "primary"}
          strokeWidth="2"
        >
          <polyline
            id="Line-11-Copy-4"
            points="272 455.921983 274.499901 458 279 451"
          ></polyline>
        </G>
      </g>
    </Svg>
  )
}

export default Checkmark
