import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import Reveal from "../utils/Reveal"

import Section from "../atoms/Section"
import Button from "../atoms/Button"
import Flex from "../atoms/Flex"
import Box from "../atoms/Box"

import SectionTitle from "../molecules/SectionTitle"
import CareerPosition from "../molecules/CareerPosition"

const Careers = ({
  section,
  sectionTitle,
  positions,
  positionsTitleColor,
  button,
  card,
}) => {
  return (
    <Section {...section}>
      <Box pb={{ _: 0, md: 6 }}>
        {sectionTitle && (
          <Reveal>
            <SectionTitle
              {...sectionTitle}
              maxWidth={42}
              mb={{ _: 6, md: 7 }}
            />
          </Reveal>
        )}
        <Reveal>
          <Box as="ul" p={0} m={0} maxWidth={50} mx="auto">
            {positions?.map((position, i) => (
              <CareerPosition
                {...position}
                card={card}
                titleColor={positionsTitleColor}
                idx={i}
                key={i}
              />
            ))}
          </Box>
        </Reveal>
        {button && (
          <Reveal>
            <Flex
              flexDirection="column"
              alignItems="center"
              mt={{ _: 6, md: 7 }}
            >
              <Button aria-label="CTA button to job listing info" {...button} />
            </Flex>
          </Reveal>
        )}
      </Box>
    </Section>
  )
}

Careers.propTypes = {
  strapi_id: PropTypes.number.isRequired,
  section: PropTypes.shape(Section.strapiProps).isRequired,
  sectionTitle: PropTypes.shape(SectionTitle.strapiProps).isRequired,
  positions: PropTypes.arrayOf(PropTypes.shape(CareerPosition.strapiProps)),
  positionsTitleColor: PropTypes.oneOf(["text", "primary"]).isRequired,
  button: PropTypes.shape(Button.strapiProps).isRequired,
}

export default Careers

export const query = graphql`
  fragment Careers on STRAPI__COMPONENT_SECTIONS_CAREERS {
    strapi_id
    CAREERSSection: section {
      ...Section
    }
    sectionTitle {
      ...SectionTitle
    }
    card {
      ...Card
    }
    positions {
      ...CareerPosition
    }
    positionsTitleColor
    button {
      ...Button
    }
  }
`
