import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { graphql, notGatsby } from "gatsby"

import Reveal from "../utils/Reveal"
import Image from "../utils/Image"

import Section from "../atoms/Section"
import StandardPadding from "../atoms/StandardPadding"
import Box from "../atoms/Box"
import Button from "../atoms/Button"

import SectionTitle from "../molecules/SectionTitle"
import Flex from "../atoms/Flex"

const ImageSection = ({
  section,
  sectionTitle,
  image,
  width,
  button,
  button2,
}) => {
  let padded, regular, extended, fullWidth
  let boxProps = {}
  switch (width) {
    case "padded":
      boxProps.mx = { _: 0, sm: 8, md: 10 }
      padded = true
      break
    case "regular":
      regular = true
      break
    case "extended":
      extended = true
      break
    case "fullWidth":
      fullWidth = true
      break
    default:
      padded = true
  }
  const [innerWidth, setInnerWidth] = useState(null)
  useEffect(() => {
    setInnerWidth(window.innerWidth)
  }, [])
  const svg =
    !image?.localFile?.childImageSharp && image?.url?.slice(-3) === "svg"

  const getImageHeight = () => {
    if (!notGatsby) {
      if (image?.localFile) {
        return (
          innerWidth *
          image?.localFile?.childImageSharp?.gatsbyImageData?.height
        )
      } else {
        //for svg and gif
        return null
      }
    } else if (notGatsby) {
      if (image?.formats) {
        return image?.formats?.large?.height + 56
      } else {
        //for svg and gif
        return image?.height + 100
      }
    }
  }

  const fullWidthAndTitle = fullWidth && sectionTitle
  const fullWidthOptionSelected = fullWidthAndTitle || fullWidth

  return (
    <Section
      {...section}
      backgroundImageMobile={
        fullWidthOptionSelected ? image : section.backgroundImageMobile
      }
      backgroundImageDesktop={
        fullWidthOptionSelected ? image : section.backgroundImageDesktop
      }
      verticalPadding={fullWidth ? null : section.verticalPadding}
      noStandardPadding={fullWidth || extended}
      fullWidthOptionSelected={fullWidthOptionSelected}
    >
      {fullWidthAndTitle && (
        <Flex
          minHeight={{ _: "60vw", lg: "40vw" }}
          alignItems="center"
          justifyContent="center"
          width="100%"
        >
          <StandardPadding
            py={{ _: 9, md: 11 }}
            maxWidth={55}
            zIndex={10}
            px={0}
          >
            <Box
              bg="rgba(255,255,255,0.5)"
              py={{ _: 7, md: 9 }}
              px={{ _: 6, md: 8 }}
            >
              <SectionTitle {...sectionTitle} maxWidth={42} />
              <Flex
                flexDirection={{ _: "column", md: "row" }}
                justifyContent={
                  sectionTitle?.align === "center" ? "center" : "flex-start"
                }
                alignItems={
                  sectionTitle?.align === "center" ? "center" : "flex-start"
                } //needed for mobile alignment
              >
                {button && (
                  <Button
                    aria-label="CTA button 1"
                    mt={5}
                    mr={{ _: 0, md: button2 ? 5 : 0 }}
                    {...button}
                  />
                )}
                {button2 && (
                  <Button aria-label="CTA button 2" mt={5} {...button2} />
                )}
              </Flex>
            </Box>
          </StandardPadding>
        </Flex>
      )}
      {fullWidth && !sectionTitle && (
        <Box
          height={getImageHeight() ? `${getImageHeight()}px` : "100vh"}
          minWidth={"100%"}
        >
          {/* PLACEHOLDER BOX TO SET HEIGHT FOR BACKGROUND IMG */}
        </Box>
      )}
      {(padded || regular) && (
        <Reveal>
          <Box>
            <Box mb={6}>
              {sectionTitle && <SectionTitle {...sectionTitle} maxWidth={42} />}
              <Flex
                flexDirection={{ _: "column", md: "row" }}
                justifyContent={
                  sectionTitle?.align === "center" ? "center" : "flex-start"
                }
                alignItems={
                  sectionTitle?.align === "center" ? "center" : "flex-start"
                } //needed for mobile alignment
              >
                {sectionTitle && button && (
                  <Button
                    aria-label="CTA button 1"
                    mt={5}
                    mr={{ _: 0, md: button2 ? 5 : 0 }}
                    {...button}
                  />
                )}
                {sectionTitle && button2 && (
                  <Button aria-label="CTA button 2" mt={5} {...button2} />
                )}
              </Flex>
            </Box>
            {image && (
              <Box
                height={{
                  _:
                    svg && padded ? "200px" : svg && regular ? "200px" : "100%",
                  sm:
                    svg && padded ? "208px" : svg && regular ? "208px" : "100%",
                  md:
                    svg && padded ? "292px" : svg && regular ? "398px" : "100%",
                  lg:
                    svg && padded ? "408px" : svg && regular ? "515px" : "100%",
                  xl:
                    svg && padded ? "500px" : svg && regular ? "608px" : "100%",
                }}
                {...boxProps}
              >
                <Image
                  alternativeText="Image In A Section Of A Page"
                  {...image}
                  style={{ height: "100%", width: "100%" }}
                  imgStyle={{ objectFit: "contain" }}
                />
              </Box>
            )}
          </Box>
        </Reveal>
      )}
      {extended && (
        <StandardPadding
          maxWidth={81}
          verticalPadding={section.verticalPadding}
          px={0}
        >
          <Reveal>
            <Box>
              <Box mb={6}>
                {sectionTitle && (
                  <SectionTitle {...sectionTitle} maxWidth={42} />
                )}
                <Flex
                  flexDirection={{ _: "column", md: "row" }}
                  justifyContent={
                    sectionTitle?.align === "center" ? "center" : "flex-start"
                  }
                  alignItems={
                    sectionTitle?.align === "center" ? "center" : "flex-start"
                  } //needed for mobile alignment
                >
                  {sectionTitle && button && (
                    <Button
                      mt={5}
                      mr={{ _: 0, md: button2 ? 5 : 0 }}
                      {...button}
                    />
                  )}
                  {sectionTitle && button2 && (
                    <Button aria-label="CTA button 2" mt={5} {...button2} />
                  )}
                </Flex>
              </Box>
              {image && (
                <Box
                  height={{
                    _: svg ? "250px" : "100%",
                    sm: svg ? "425px" : "100%",
                    md: svg ? "398px" : "100%",
                    lg: svg ? "568px" : "100%",
                    xl: svg ? "626px" : "100%",
                  }}
                  {...boxProps}
                >
                  <Image
                    alternativeText="image section of a page"
                    {...image}
                    style={{ height: "100%", width: "100%" }}
                    imgStyle={{ objectFit: "contain" }}
                  />
                </Box>
              )}
            </Box>
          </Reveal>
        </StandardPadding>
      )}
    </Section>
  )
}

ImageSection.strapiProps = {
  strapi_id: PropTypes.number.isRequired,
  section: PropTypes.shape(Section.strapiProps).isRequired,
  sectionTitle: PropTypes.shape(SectionTitle.strapiProps),
  image: PropTypes.object,
  width: PropTypes.oneOf(["padded", "regular", "extended", "fullWidth"]),
  button: PropTypes.shape(Button.strapiProps),
}

ImageSection.propTypes = {
  ...ImageSection.strapiProps,
}

export default ImageSection

export const query = graphql`
  fragment ImageSection on STRAPI__COMPONENT_SECTIONS_IMAGE {
    strapi_id
    IMAGESection: section {
      ...Section
    }
    sectionTitle {
      ...SectionTitle
    }
    image {
      strapi_id
      url
      alternativeText
      height
      width
      localFile {
        childImageSharp {
          gatsbyImageData(width: 1500, quality: 100, layout: FULL_WIDTH)
        }
      }
    }
    width
    button {
      ...Button
    }
    button2 {
      ...Button
    }
  }
`
