import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import { Paragraph } from "../atoms/Typography"
import Box from "../atoms/Box"

const ContactInfo = ({
  addressLineOne,
  addressLineTwo,
  email,
  phone,
  ...rest
}) => {
  return (
    <Box {...rest}>
      <div>
        {addressLineOne && <Paragraph>{addressLineOne}</Paragraph>}
        {addressLineTwo && <Paragraph>{addressLineTwo}</Paragraph>}
      </div>
      <Box mt={6}>
        {email && <Paragraph>{email}</Paragraph>}
        {phone && <Paragraph>{phone}</Paragraph>}
      </Box>
    </Box>
  )
}

export default ContactInfo

ContactInfo.strapiProps = {
  addressLineOne: PropTypes.string,
  addressLineTwo: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
}

export const query = graphql`
  fragment ContactInfo on STRAPI__COMPONENT_MOLECULES_CONTACT_INFO {
    addressLineOne
    addressLineTwo
    email
    phone
  }
`
