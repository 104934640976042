import React from "react"
import { graphql } from "gatsby"
import { Title } from "../atoms/Typography"
import Section from "../atoms/Section"
import Accordions from "../molecules/Accordions"
import Faq from "../molecules/Faq"
import SectionTitle from "../molecules/SectionTitle"

const Faqs = ({
  section,
  sectionTitle,
  faqs,
  card,
  labelStyles,
  contentStyles,
}) => {
  const faqItems = faqs?.map(({ title, richText, faqItemRest }) => {
    return {
      label: (
        <Title textAlign="left" variant="h3" mb={3} {...faqItemRest}>
          {title}
        </Title>
      ),
      content: <Faq title={title} richText={richText} />,
    }
  })

  return (
    <Section {...section}>
      {sectionTitle && <SectionTitle {...sectionTitle} maxWidth={42} mb={6} />}
      <Accordions
        items={faqItems}
        card={card}
        toggleMany={true}
        labelStyles={labelStyles}
        contentStyles={contentStyles}
      />
    </Section>
  )
}

export default Faqs

export const query = graphql`
  fragment Faqs on STRAPI__COMPONENT_SECTIONS_FAQS {
    strapi_id
    FAQSSection: section {
      ...Section
    }
    sectionTitle {
      ...SectionTitle
    }
    faqs {
      ...Faq
    }
    card {
      ...Card
    }
  }
`
