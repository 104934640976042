import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import styled from "styled-components"
import { space } from "styled-system"

import Flex from "../atoms/Flex"
import Card from "../atoms/Card"
import { Title, Paragraph } from "../atoms/Typography"
import Button from "../atoms/Button"

// TODO: Should be it's own atom
const StyledList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  ${space}
`

const PricingGridTier = ({
  name,
  gridName,
  largeText,
  gridLargeText,
  smallText,
  gridSmallText,
  list,
  theme = "default",
  bg = 0,
  index,
  button,
  largeTextColor,
  align,
  card,
}) => {
  return (
    <Flex>
      <Card
        index={index}
        px={{ _: 5, sm: 6 }}
        py={6}
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        {...card}
        theme={theme}
        bg={bg}
        width={"100%"}
      >
        <div>
          {(name || gridName) && (
            <Title textAlign={align} variant="h3" mb={3}>
              {name || gridName}
            </Title>
          )}
          {(largeText || gridLargeText) && (
            <Paragraph textAlign={align} fontSize={5} color={largeTextColor}>
              {largeText || gridLargeText}
            </Paragraph>
          )}
          {(smallText || gridSmallText) && (
            <Paragraph textAlign={align} mt={4}>
              {smallText || gridSmallText}
            </Paragraph>
          )}
        </div>
      </Card>
    </Flex>
  )
}

export default PricingGridTier

PricingGridTier.strapiProps = {
  name: PropTypes.string,
  largeText: PropTypes.string,
  smallText: PropTypes.string,
  button: PropTypes.shape(Button.strapiProps),
  list: PropTypes.arrayOf(PropTypes.object),
}

PricingGridTier.propTypes = {
  ...PricingGridTier.strapiProps,
  ...Card.propTypes,
}

export const query = graphql`
  fragment PricingGridTier on STRAPI__COMPONENT_MOLECULES_PRICING_GRID_TIER {
    gridName: name
    gridLargeText: largeText
    gridSmallText: smallText
    theme
    bg
    list {
      __typename
      ... on STRAPI__COMPONENT_UTILS_TEXT {
        text
      }
      ... on STRAPI__COMPONENT_UTILS_TITLED_LIST {
        title
        list {
          text
        }
      }
    }
    selectCategory {
      ... on STRAPI__COMPONENT_UTILS_TEXT_BOOL {
        boolean
        text
      }
    }
    button {
      ...Button
    }
  }
`
