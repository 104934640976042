import React from "react"
import PropTypes from "prop-types"
import { graphql, notGatsby } from "gatsby"

import Reveal from "../utils/Reveal"

import Card from "../atoms/Card"
import FlexGrid from "../atoms/FlexGrid"
import Filter from "../atoms/Filter"
import Section from "../atoms/Section"
import Box from "../atoms/Box"

import Logo from "../molecules/Logo"
import ScrollCarousel from "../molecules/ScrollCarousel"
import FilteredGrid from "../molecules/FilteredGrid"
import SectionTitle from "../molecules/SectionTitle"
import { sectionTitleHasText } from "../../utils/utils"

const LogoGrid = ({
  cardBack,
  cardFront,
  cardMaxHeight,
  customCardMaxHeight,
  imagePadding,
  overlayImagePadding,
  overlayOpacity,
  imageFit,
  customImageWidth,
  customImageHeight,
  customSizeMobile,
  carouselWidth,
  carouselSpeed,
  cardRevealAlign,
  cardRevealVerticalAlign,
  displayMode = "grid",
  filter,
  LGFilter,
  filters,
  LGFilters,
  flexGrid,
  LGFlexGrid,
  logoItems,
  section,
  sectionTitle,
  LGSectionTitle,
  useCardBack,
  LGCardBack,
  useCardFront,
  LGCardFront,
  zoomOnHover,
  hoverBehavior = "none",
}) => {
  sectionTitle = LGSectionTitle || sectionTitle
  flexGrid = LGFlexGrid || flexGrid
  cardFront = LGCardFront || cardFront
  cardBack = LGCardBack || cardBack
  filters = LGFilters || filters
  filter = LGFilter || filter

  cardMaxHeight = cardMaxHeight === null ? "fullHeight" : cardMaxHeight
  customCardMaxHeight = customCardMaxHeight === null ? 328 : customCardMaxHeight

  cardFront = {
    ...cardFront,
    useCard: useCardFront,
  }
  cardBack = {
    ...cardFront,
    useCard: hoverBehavior === "info" || hoverBehavior === "all",
    theme: cardBack?.theme,
    bg: cardBack?.bg,
  }

  const isScroll = displayMode === "scroll"
  const breakpoints = Object.keys(flexGrid?.columns || {})
  const columnsOnBiggestBp =
    flexGrid?.columns[`${breakpoints[breakpoints.length - 1]}`]
  const imagePaddingStyle = {
    _: 4,
    md: !isScroll && columnsOnBiggestBp < 3 ? 7 : 5,
  }
  const logoGridImageStyles = {
    imageStyles: {
      padding: imagePadding === "fill" ? null : imagePaddingStyle,
      objectFit: imagePadding === "fill" ? "cover" : "contain",
    },
    overlayImageStyles: {
      padding: overlayImagePadding === "fill" ? null : imagePaddingStyle,
      objectFit: overlayImagePadding === "fill" ? "cover" : "contain",
      opacity: hoverBehavior === "all" ? overlayOpacity : 1,
    },
  }

  return (
    <Section {...section}>
      {sectionTitleHasText(sectionTitle, notGatsby) && (
        <Reveal>
          <SectionTitle {...sectionTitle} mb={7} maxWidth={42} />
        </Reveal>
      )}
      {isScroll && (
        <Box>
          <Box
            position={"relative"}
            width={carouselWidth === "normal" ? "100%" : "100vw"}
            ml={carouselWidth === "normal" ? undefined : "-50vw"}
            left={carouselWidth === "normal" ? undefined : "49.3%"}
          >
            <ScrollCarousel
              cardFront={cardFront}
              cardBack={cardBack}
              align={cardRevealAlign}
              verticalAlign={cardRevealVerticalAlign}
              carouselItems={logoItems}
              hoverBehavior={hoverBehavior}
              zoomOnHover={zoomOnHover}
              logoOrImage={"logo"}
              customImageWidth={imageFit === "width" ? customImageWidth : null}
              customImageHeight={
                imageFit === "height" ? customImageHeight : null
              }
              customSizeMobile={customSizeMobile}
              carouselSpeed={carouselSpeed}
              logoGridImageStyles={logoGridImageStyles}
            />
          </Box>
        </Box>
      )}
      {!isScroll && (
        <FilteredGrid
          flexGrid={flexGrid}
          filters={filters}
          filtersStyles={{
            filterLocation: filter?.filterLocation,
            alignMenuItems: filter?.alignMenuItems,
            showFilterLabel: filter?.showFilterLabel,
            layout: filter?.layout,
            dropdownCard: filter?.dropdownCard,
          }} // only sending style fields to avoid possibly overwriting filter data
        >
          {logoItems &&
            logoItems.map((logo, i) => (
              <Logo
                height="100%"
                zoomOnHover={zoomOnHover}
                hoverBehavior={hoverBehavior}
                cardFront={cardFront}
                cardBack={cardBack}
                align={cardRevealAlign}
                verticalAlign={cardRevealVerticalAlign}
                cardMaxHeight={cardMaxHeight}
                customCardMaxHeight={customCardMaxHeight}
                customImageWidth={customImageWidth}
                {...logoGridImageStyles}
                {...logo}
                key={i}
              />
            ))}
        </FilteredGrid>
      )}
    </Section>
  )
}

export default LogoGrid

LogoGrid.propTypes = {
  strapi_id: PropTypes.number.isRequired,
  cardBack: PropTypes.shape(Card.strapiProps),
  cardFront: PropTypes.shape(Card.strapiProps),
  cardMaxHeight: PropTypes.oneOf(["default", "square", "custom"]),
  cardRevealAlign: PropTypes.oneOf(["left", "center"]),
  cardRevealVerticalAlign: PropTypes.oneOf(["default", "center"]),
  customCardMaxHeight: PropTypes.number,
  displayMode: PropTypes.oneOf(["grid", "scroll"]),
  filters: PropTypes.arrayOf(PropTypes.shape(Filter.strapiProps)),
  filter: PropTypes.shape(Filter.strapiProps),
  flexGrid: PropTypes.shape(FlexGrid.strapiProps).isRequired,
  logoItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  section: PropTypes.shape(Section.strapiProps).isRequired,
  sectionTitle: PropTypes.shape(SectionTitle.strapiProps).isRequired,
  useCardBack: PropTypes.bool,
  useCardFront: PropTypes.oneOf(["card", "noCard"]),
}

export const query = graphql`
  fragment LogoGrid on STRAPI__COMPONENT_SECTIONS_LOGO_GRID {
    strapi_id
    LOGOGRIDSection: section {
      ...Section
    }
    LGSectionTitle: sectionTitle {
      ...SectionTitle
    }
    LGFlexGrid: flexGrid {
      ...FlexGrid
    }
    logoItems {
      ...Logo
    }
    LGCardFront: cardFront {
      ...Card
    }
    LGCardBack: cardBack {
      ...Card
    }
    LGFilters: filters {
      ...Filter
    }
    LGFilter: filter {
      alignMenuItems
      filterLocation
      showFilterLabel
      layout
      dropdownCard {
        ...Card
      }
    }
    useCardFront
    useCardBack
    cardRevealAlign
    cardRevealVerticalAlign
    displayMode
    zoomOnHover
    hoverBehavior
    cardMaxHeight
    customCardMaxHeight
    imagePadding
    overlayImagePadding
    overlayOpacity
    imageFit
    customImageWidth
    customImageHeight
    customSizeMobile
    carouselWidth
    carouselSpeed
  }
`
