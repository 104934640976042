import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import Flex from "../atoms/Flex"
import { Title, Paragraph } from "../atoms/Typography"

const Stat = ({ stat, name, description, align, color, size }) => {
  const center = align === "center"
  const statTextAlignment = align === "center" ? "center" : undefined
  return (
    <Flex
      maxWidth={25}
      mx={center ? "auto" : 0}
      height="100%"
      flexDirection="column"
      alignItems={center ? "center" : null}
    >
      <Title
        fontSize={size ? `${size}px` : 6}
        color={color || "primary"}
        style={{ textAlign: statTextAlignment }}
      >
        {stat}
      </Title>
      <Title variant="h3" mb={4} textAlign={center ? "center" : null}>
        {name}
      </Title>
      {description && (
        <Paragraph textAlign={center ? "center" : null}>
          {description}
        </Paragraph>
      )}
    </Flex>
  )
}

export default Stat

Stat.strapiProps = {
  stat: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.string,
}

Stat.propTypes = {
  ...Stat.strapiProps,
  align: PropTypes.oneOf(["left", "center"]).isRequired,
}

export const query = graphql`
  fragment Stat on STRAPI__COMPONENT_MOLECULES_STAT {
    stat
    name
    description
  }
`
