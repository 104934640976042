import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"

import Reveal from "../utils/Reveal"

import Section from "../atoms/Section"
import Flex from "../atoms/Flex"

import DraggableCarousel from "../molecules/DraggableCarousel"
import SectionTitle from "../molecules/SectionTitle"

const CardCarousel = ({ section, sectionTitle, cardCarousel, children }) => {
  const [carouselItems, setCarouselItems] = useState(
    React.Children.map(children, (child, i) => ({ child, key: i }))
  )

  useEffect(() => {
    setCarouselItems(
      React.Children.map(children, (child, i) => ({ child, key: i }))
    )
  }, [children])

  const cardWidth = cardCarousel?.cardWidth ? cardCarousel?.cardWidth : "300px"
  const cardCount =
    cardCarousel?.previewCount === "one"
      ? 1
      : cardCarousel?.previewCount === "three"
      ? 2
      : 3

  return (
    <Section {...section}>
      {sectionTitle && (
        <Reveal>
          <SectionTitle {...sectionTitle} mb={6} maxWidth={42} />
        </Reveal>
      )}
      {carouselItems?.length > 0 && (
        <DraggableCarousel
          rotateSpeed={cardCarousel?.rotateSpeed}
          numItems={carouselItems?.length}
          cardCount={cardCount}
          indicatorStyle={cardCarousel?.indicatorStyle}
          showArrows={cardCarousel?.showArrows}
          stopHoverEffect={true}
        >
          {carouselItems.map((item, index) => {
            return (
              <Flex
                justifyContent="center"
                minWidth={"290px"}
                minHeight={"200px"}
                width={cardWidth}
                height={"100%"}
              >
                {item?.child}
              </Flex>
            )
          })}
        </DraggableCarousel>
      )}
    </Section>
  )
}

export default CardCarousel

export const query = graphql`
  fragment CardCarousel on STRAPI__COMPONENT_MOLECULES_CARD_CAROUSEL {
    useCarousel
    previewCount
    rotateSpeed
    cardWidth
    indicatorStyle
    showArrows
  }
`
