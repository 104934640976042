import React, { useRef, useEffect, useState, createRef } from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import { color } from "styled-system"
import styled from "styled-components"
import css from "@styled-system/css"

import Reveal from "../utils/Reveal"

import Section from "../atoms/Section"
import StandardPadding from "../atoms/StandardPadding"
import Flex from "../atoms/Flex"
import Box from "../atoms/Box"
import { Title } from "../atoms/Typography"

const TitleRefWrapper = styled.div`
  display: block;
`

const TitleContainer = styled(Box)`
  transition: ${props =>
    (props.showTitleEnding ? "width 0.4s ease, " : "") + "opacity 0.3s ease"};
  overflow: hidden;
`

const StyledTitle = styled(Title)`
  transition: height 0.4s ease;
  position: relative;
`

const TitleBeginning = styled.span`
  position: absolute;
  left: 0;
  z-index: 2;
  ${color}
`

const TitleEnding = styled.span`
  /* ${css({
    color: "primary",
  })} */
  ${color}
  z-index: 1;
  position: absolute;
  overflow: hidden;
  white-space: pre;
  right: 0;
`

// const WordContainer = styled.div`
//   display: inline-block;
//   position: relative;
//   overflow: ${props => props.overflow || "hidden"};
//   div {
//     ${css({
//       color: "primary",
//     })}
//     transform: ${props => (props.show ? "translateY(0)" : "translateY(100%)")};
//     white-space: nowrap;
//     transition-property: transform;
//     transition-duration: 0.4s;
//     transition-timing-function: ease;
//   }
// `

const INTERVAL = 4000
const ANIMATION_DURATION = 400
const FADE_DURATION = 300

/**
 * The main idea here is that we are rendering and then hiding
 * all of the titles. We use these hidden titles to know
 * how wide the title will be when it switches among the endings.
 * We use these pre-determined widths to animate both the title
 * and Underline width.
 */
const DynamicTitle = ({
  section,
  previewScale,
  animatedTitles,
  variant,
  titleColor,
}) => {
  const [activeIdx, setActiveIdx] = useState(0)
  const [titleWidth, setTitleWidth] = useState(null)
  const [titleHeight, setTitleHeight] = useState(null)
  const [showTitleEnding, setShowTitleEnding] = useState(true)
  const [showTitle, setShowTitle] = useState(false)
  const titlesRef = useRef(animatedTitles?.map(() => createRef()))
  const titleBeginningsRef = useRef(animatedTitles?.map(() => createRef()))

  function getTitleWidth(idx, isBeginning) {
    if (isBeginning)
      return titleBeginningsRef.current[idx].current.getBoundingClientRect()
        .width
    return titlesRef.current[idx].current.getBoundingClientRect().width
  }

  function resetTitleSizes() {
    if (titlesRef.current[activeIdx]) {
      let titleBoundingClientRect = titlesRef.current[
        activeIdx
      ].current.getBoundingClientRect()
      setTitleWidth(titleBoundingClientRect.width)
      setTitleHeight(titleBoundingClientRect.height)
    }
  }

  useEffect(() => {
    if (animatedTitles?.length > 0) {
      window.addEventListener("resize", resetTitleSizes)
      return () => {
        window.removeEventListener("resize", resetTitleSizes)
      }
    }
  }, [activeIdx, animatedTitles, setTitleWidth, setTitleHeight])

  useEffect(() => {
    if (animatedTitles?.length > 0) {
      resetTitleSizes()
    }
  }, [titlesRef, animatedTitles, activeIdx, variant])

  /**
   * Set interval/timeout to first hide the current title and
   * then change activeIdx to the next title. Changing activeIdx
   * will then trigger the useEffect below this one
   */
  useEffect(() => {
    let hideTitleTimeout = setInterval(() => {
      setShowTitle(false)
    }, INTERVAL - ANIMATION_DURATION)
    let changeActiveIdxInterval = setInterval(() => {
      setActiveIdx((activeIdx + 1) % animatedTitles?.length)
    }, INTERVAL)
    return () => {
      clearTimeout(hideTitleTimeout)
      clearInterval(changeActiveIdxInterval)
    }
  }, [activeIdx, setActiveIdx, setShowTitle, animatedTitles])

  /**
   * After the title has changed, wait for ANIMATION_DURATION
   * before displaying the new title
   */
  useEffect(() => {
    if (animatedTitles?.length > 0) {
      let showTitleTimeout = setTimeout(() => {
        setShowTitle(true)
        setTitleWidth(getTitleWidth(activeIdx, true))
        setShowTitleEnding(false)
      }, ANIMATION_DURATION)
      return () => {
        clearTimeout(showTitleTimeout)
      }
    }
  }, [activeIdx, animatedTitles, setShowTitle])

  /**
   * After the new title has displayed, wait for the opacity transition
   * to finish before displaying the ending text
   */
  useEffect(() => {
    if (showTitle && animatedTitles?.length > 0) {
      let showTitleEndingTimeout = setTimeout(() => {
        setTitleWidth(getTitleWidth(activeIdx))
        setShowTitleEnding(true)
      }, FADE_DURATION)
      return () => {
        clearTimeout(showTitleEndingTimeout)
      }
    }
  }, [showTitle, animatedTitles, setShowTitleEnding])

  // const titleFontSize = { _: "6.1vw", sm: 5 }

  return (
    <Section {...section} noStandardPadding>
      {/* ======================================================= */}
      {/* ======HIDDEN TITLES FOR WIDTH/HEIGHT CALCULATIONS====== */}
      {/* ======================================================= */}
      <Box
        style={{
          transform: previewScale ? `scale(${1 / previewScale})` : null,
          visibility: "hidden",
        }}
        width="100%"
        position="absolute"
        zIndex="-1"
      >
        <StandardPadding
          verticalPadding={section.verticalPadding}
          position="relative"
          zIndex={10}
        >
          <Flex flexDirection="column" width="100%" alignItems="center">
            {/* use these invisible boxes to get the size of each for animation purposes */}
            {animatedTitles?.map(({ text1, text2 }, idx) => {
              return (
                <TitleRefWrapper ref={titlesRef.current[idx]}>
                  <Title
                    // fontSize={titleFontSize}
                    key={idx}
                    variant={variant ? variant : "h1"}
                  >
                    <Flex>
                      <div
                        style={{
                          lineHeight: "initial",
                          display: "inline-block",
                          whiteSpace: "pre",
                        }}
                        ref={titleBeginningsRef.current[idx]}
                      >
                        {text1}
                      </div>
                      <div
                        style={{
                          lineHeight: "initial",
                          display: "inline-block",
                          whiteSpace: "pre",
                        }}
                      >
                        {text2}
                      </div>
                    </Flex>
                  </Title>
                </TitleRefWrapper>
              )
            })}
          </Flex>
        </StandardPadding>
      </Box>
      {/* ===================================================== */}
      {/* =========VISIBLE ACTIVE TITLE WITH ANIMATION========= */}
      {/* ===================================================== */}
      <StandardPadding
        verticalPadding={section.verticalPadding}
        position="relative"
        zIndex={10}
      >
        <Reveal>
          <Flex width="100%" justifyContent="center">
            {titleWidth && animatedTitles.length > 0 && (
              <TitleContainer
                showTitleEnding={showTitleEnding}
                opacity={showTitle ? 1 : 0}
                position="relative"
                width={titleWidth}
                height={`${titleHeight}px`}
              >
                <StyledTitle
                  // fontSize={titleFontSize}
                  variant={variant ? variant : "h1"}
                >
                  <TitleBeginning
                    bg={`background.${section.bg || 0}`}
                    color={
                      titleColor === "text1" || titleColor === null
                        ? "primary"
                        : "auto"
                    }
                  >
                    {animatedTitles[activeIdx].text1}
                  </TitleBeginning>
                  <TitleEnding
                    width="100%"
                    color={titleColor === "text2" ? "primary" : "auto"}
                  >
                    {animatedTitles[activeIdx].text2}
                  </TitleEnding>
                </StyledTitle>
              </TitleContainer>
            )}
          </Flex>
        </Reveal>
      </StandardPadding>
    </Section>
  )
}

DynamicTitle.propTypes = {
  strapi_id: PropTypes.number.isRequired,
  section: PropTypes.shape(Section.strapiProps).isRequired,
  titleBeginning: PropTypes.string.isRequired,
}

export default DynamicTitle

export const query = graphql`
  fragment DynamicTitle on STRAPI__COMPONENT_SECTIONS_DYNAMIC_TITLE {
    strapi_id
    DYNAMICTITLESection: section {
      ...Section
    }
    animatedTitles {
      text1
      text2
    }
    variant
    titleColor
  }
`
