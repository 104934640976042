import React, { useState } from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import Reveal from "../utils/Reveal"

import Card from "../atoms/Card" // import for propTypes
import Section from "../atoms/Section"
import FlexGrid from "../atoms/FlexGrid"

import FilteredGrid from "../molecules/FilteredGrid"

import SectionTitle from "../molecules/SectionTitle"
import CompanyCard from "../molecules/CompanyCard"

const PortfolioGrid = ({
  section,
  sectionTitle,
  PGSectionTitle,
  flexGrid,
  PGFlexGrid,
  companyCards,
  filter,
  PGFilter,
  card,
  PGCard,
  portfolioGridImageLocation,
  imageLocation, // for component preview
}) => {
  sectionTitle = PGSectionTitle || sectionTitle
  flexGrid = PGFlexGrid || flexGrid
  card = PGCard || card
  filter = PGFilter || filter

  const center = !sectionTitle || sectionTitle?.align === "center"

  return (
    <Section {...section}>
      {sectionTitle && (
        <Reveal>
          <SectionTitle {...sectionTitle} mb={6} maxWidth={42} />
        </Reveal>
      )}
      <FilteredGrid
        centerFilter={center}
        filter={filter}
        flexGrid={flexGrid}
        card={{
          theme: section.theme,
          bg: section.bg,
          // borderRadius: "md",
          // boxShadow: "md",
          boxShadow: "0 3px 10px rgb(0 0 0 / 30%)",
        }}
      >
        {companyCards?.map((company, i) => {
          return (
            <CompanyCard
              sectionTheme={section.theme}
              key={`${company.name}-${i}`}
              forwardKey={`${company.name}`}
              {...company}
              imageLocation={portfolioGridImageLocation || imageLocation} // component preview
              card={card}
            />
          )
        })}
      </FilteredGrid>
    </Section>
  )
}

export default PortfolioGrid

PortfolioGrid.strapiProps = {
  strapi_id: PropTypes.number.isRequired,
  section: PropTypes.shape(Section.strapiProps).isRequired,
  sectionTitle: PropTypes.shape(SectionTitle.strapiProps).isRequired,
  flexGrid: PropTypes.shape(FlexGrid.strapiProps),
  portfolioGridImageLocation: PropTypes.oneOf(["default", "top", "innerRight"]),
  card: PropTypes.shape(Card.strapiProps),
  companyCards: PropTypes.arrayOf(PropTypes.shape(CompanyCard.strapiProps)),
}

PortfolioGrid.propTypes = {
  ...PortfolioGrid.strapiProps,
}

export const query = graphql`
  fragment PortfolioGrid on STRAPI__COMPONENT_SECTIONS_PORTFOLIO_GRID {
    strapi_id
    PORTFOLIOGRIDSection: section {
      ...Section
    }
    PGSectionTitle: sectionTitle {
      ...SectionTitle
    }
    PGFlexGrid: flexGrid {
      ...FlexGrid
    }
    companyCards {
      ...CompanyCard
    }
    PGCard: card {
      ...Card
    }
    PGFilter: filter {
      ...Filter
    }
    portfolioGridImageLocation: imageLocation
  }
`
