import React, { useState } from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import styled from "styled-components"

import Image from "../utils/Image"
import Reveal from "../utils/Reveal"

import Card from "../atoms/Card"
import Section from "../atoms/Section"
import Flex from "../atoms/Flex"
import Box from "../atoms/Box"
import { Title } from "../atoms/Typography"

import InteractiveInfoCard from "../molecules/InteractiveInfoCard"
import SectionTitle from "../molecules/SectionTitle"

const StyledBox = styled(Box)`
  transition: opacity 0.3s cubic-bezier(0.17, 0.67, 0.58, 0.84);
`

const StyledTitle = styled(Title)`
  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
`

const StyledImage = styled(Image)`
  &.gatsby-image-wrapper,
  &.gatsby-image-wrapper-constrained {
    & div {
      max-width: 100% !important;
    }
  }
`

const TitleTabs = ({
  cardTitleLocation,
  interactiveInfoCards,
  changeOnHover,
  activeIndex,
  setActiveIndex,
  textAlign,
  ...rest
}) =>
  interactiveInfoCards.map((interactiveInfoCard, i) => (
    <StyledTitle
      variant="h3"
      pb={{ _: 0, sm: 5, lg: 7 }}
      mb={{ _: 5, sm: 0 }}
      margin={textAlign === "center" ? { _: "0px auto", sm: "0px" } : "0px"}
      pr={
        textAlign !== "center"
          ? { _: 4, xs: 6, md: 7 }
          : interactiveInfoCards.length - 1 === i
          ? { _: 0, sm: 7 }
          : { _: 0, sm: 7 }
      }
      onClick={
        !changeOnHover && cardTitleLocation === "tabs"
          ? () => setActiveIndex(i)
          : undefined
      }
      onMouseEnter={
        changeOnHover && cardTitleLocation === "tabs"
          ? () => setActiveIndex(i)
          : undefined
      }
      textDecoration={i === activeIndex ? "underline" : "none"}
      style={{ textUnderlinePosition: "under" }}
      {...rest}
    >
      {interactiveInfoCard.title}
    </StyledTitle>
  ))

const InteractiveInfoCards = ({
  section,
  sectionTitle,
  interactiveInfoCards,
  card,
  cardsAlign = "left",
  iconLocation = "top",
  imageLocation,
  interactiveInfoCardsImageLocation,
  imageAlign,
  interactiveInfoCardsImageAlign,
  iconSize,
  changeOnHover,
  cardTitleLocation = "onCard",
  mediaRatio,
  interactiveInfoCardsMediaRatio,
}) => {
  if (cardTitleLocation === null) cardTitleLocation = "onCard"
  const [activeIndex, setActiveIndex] = useState(0)
  const imageAlignment = imageLocation || interactiveInfoCardsImageLocation
  const imageVertAlignment = imageAlign || interactiveInfoCardsImageAlign

  const ratio =
    mediaRatio === null
      ? 50 // defaulting mediaRatio 50% for layoutStyle standand or if unefined
      : mediaRatio || interactiveInfoCardsMediaRatio
  return (
    <Section {...section}>
      {sectionTitle && (
        <Reveal>
          <SectionTitle {...sectionTitle} mb={6} maxWidth={42} />
        </Reveal>
      )}

      <Box
        display="flex"
        flexDirection="row"
        justifyContent={
          sectionTitle.align === "center"
            ? { _: "space-between", xs: "center" }
            : "start"
        }
        flexWrap="wrap"
      >
        {cardTitleLocation === "tabs" && (
          <TitleTabs
            cardTitleLocation={cardTitleLocation}
            interactiveInfoCards={interactiveInfoCards}
            changeOnHover={changeOnHover}
            activeIndex={activeIndex}
            setActiveIndex={setActiveIndex}
            textAlign={sectionTitle.align}
          />
        )}
      </Box>

      <Flex
        justifyContent="space-between"
        width="100%"
        flexDirection={{
          _: "column",
          lg: imageAlignment === "right" ? "row-reverse" : "row",
        }}
        alignItems={
          cardTitleLocation === "tabs" && imageVertAlignment === "center"
            ? "center"
            : cardTitleLocation === "tabs" && imageVertAlignment === "bottom"
            ? "end"
            : cardTitleLocation === "tabs" && imageVertAlignment === "top"
            ? "start"
            : "stretch"
        }
      >
        <Box
          width={{ _: "100%", lg: "50%" }}
          flex={Math.abs((3.5 * ratio - 100) / 100)}
          mb={{ _: 6, lg: 0 }}
        >
          {interactiveInfoCards?.map((card, idx) => {
            return (
              <StyledBox
                display="flex"
                flexDirection="column"
                justifyContent={
                  imageVertAlignment === "top"
                    ? "start"
                    : imageVertAlignment === "center"
                    ? "center"
                    : imageVertAlignment === "bottom"
                    ? "flex-end"
                    : "space-between"
                }
                width={"100%"}
                opacity={idx === activeIndex ? 1 : 0}
                height={idx === activeIndex ? "100%" : "0px"}
                transform={idx === activeIndex ? "0px" : "translateX(1000%)"}
              >
                <StyledImage {...card.image} />
              </StyledBox>
            )
          })}
        </Box>
        <Box
          width={{ _: "100%", lg: "45%" }}
          flex={Math.abs(1 - (2 * ratio - 100) / 100)}
        >
          <Box
            display="flex"
            width="100%"
            flexDirection="column"
            alignItems={{ _: "center" }}
            pl={ratio > 51 && imageAlignment === "left" ? 6 : 0}
            pr={ratio > 51 && imageAlignment === "right" ? 6 : 0}
          >
            {cardTitleLocation === "tabs" && (
              <InteractiveInfoCard
                mx={cardsAlign === "center" ? "auto" : 0}
                width={"100%"}
                iconLocation={iconLocation}
                iconSize={iconSize}
                align={cardsAlign}
                {...interactiveInfoCards[activeIndex]}
                showTitle={cardTitleLocation !== "tabs"}
                key={activeIndex}
                idx={activeIndex}
                active={true}
                onClick={
                  !changeOnHover && cardTitleLocation === "onCard"
                    ? () => setActiveIndex(activeIndex)
                    : undefined
                }
                onMouseEnter={
                  changeOnHover && cardTitleLocation === "onCard"
                    ? () => setActiveIndex(activeIndex)
                    : undefined
                }
                card={card}
              />
            )}
            {cardTitleLocation === "onCard" &&
              interactiveInfoCards.map((interactiveInfoCard, i) => (
                <InteractiveInfoCard
                  mx={cardsAlign === "center" ? "auto" : 0}
                  mb={i === interactiveInfoCards.length - 1 ? 0 : 5}
                  width={"100%"}
                  iconLocation={iconLocation}
                  iconSize={iconSize}
                  align={cardsAlign}
                  {...interactiveInfoCard}
                  showTitle={cardTitleLocation !== "tabs"}
                  key={i}
                  idx={i}
                  active={activeIndex === i}
                  onClick={
                    !changeOnHover && cardTitleLocation === "onCard"
                      ? () => setActiveIndex(i)
                      : undefined
                  }
                  onMouseEnter={
                    changeOnHover && cardTitleLocation === "onCard"
                      ? () => setActiveIndex(i)
                      : undefined
                  }
                  card={card}
                />
              ))}
          </Box>
        </Box>
      </Flex>
    </Section>
  )
}

export default InteractiveInfoCards

InteractiveInfoCards.propTypes = {
  strapi_id: PropTypes.number.isRequired,
  section: PropTypes.shape(Section.strapiProps).isRequired,
  sectionTitle: PropTypes.shape(SectionTitle.strapiProps).isRequired,
  interactiveInfoCards: PropTypes.arrayOf(
    PropTypes.shape(InteractiveInfoCard.strapiProps)
  ),
  cardsAlign: PropTypes.oneOf(["left", "center"]).isRequired,
  iconLocation: PropTypes.oneOf(["top", "left"]).isRequired,
  card: PropTypes.shape(Card.strapiProps).isRequired,
  image: PropTypes.object,
  imageLocation: PropTypes.oneOf(["left", "right"]),
}

export const query = graphql`
  fragment InteractiveInfoCards on STRAPI__COMPONENT_SECTIONS_INTERACTIVE_INFO_CARDS {
    strapi_id
    changeOnHover
    interactiveInfoCardsImageLocation: imageLocation
    interactiveInfoCardsImageAlign: imageAlign
    interactiveInfoCardsMediaRatio: mediaRatio
    cardTitleLocation
    INTERACTIVEINFOCARDSSection: section {
      ...Section
    }
    sectionTitle {
      ...SectionTitle
    }
    interactiveInfoCards {
      ...InteractiveInfoCard
    }
    card {
      ...Card
    }
  }
`
