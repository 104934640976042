import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import Image from "../utils/Image"

import Box from "../atoms/Box"
import Button from "../atoms/Button"
import Flex from "../atoms/Flex"
import LayoutChanger from "../atoms/LayoutChanger"
import Section from "../atoms/Section"

import SectionTitle from "../molecules/SectionTitle"
import ContactInfo from "../molecules/ContactInfo"

const Contact = ({
  section,
  sectionTitle,
  contactInfo,
  button,
  image,
  layoutChanger,
}) => {
  return (
    <Section {...section}>
      <LayoutChanger
        itemsMoveStackedOnColumn={true}
        child1Style={
          image && {
            justifyContent:
              sectionTitle.align === "center" ? "center" : "flex-start",
          }
        }
        {...layoutChanger}
      >
        <Flex
          maxWidth={44}
          flexDirection="column"
          alignItems={sectionTitle.align === "center" ? "center" : "flex-start"}
        >
          {sectionTitle && <SectionTitle {...sectionTitle} />}
          {contactInfo && (
            <ContactInfo
              textAlign={sectionTitle.align === "center" ? "center" : "left"}
              mt={6}
              {...contactInfo}
            />
          )}
          {button && (
            <Button
              aria-label="CTA button in contact section"
              {...button}
              mt={6}
            />
          )}
        </Flex>
        {image && (
          <Box width="100%" maxWidth={44}>
            <Image alternativeText="Image In A Contact Form" {...image} />
          </Box>
        )}
      </LayoutChanger>
    </Section>
  )
}

export default Contact

Contact.propTypes = {
  strapi_id: PropTypes.number.isRequired,
  section: PropTypes.shape(Section.strapiProps).isRequired,
  sectionTitle: PropTypes.shape(SectionTitle.strapiProps).isRequired,
  contactInfo: PropTypes.shape(ContactInfo.strapiProps),
  button: PropTypes.shape(Button.strapiProps),
  image: PropTypes.object,
  ...LayoutChanger.strapiProps,
}

export const query = graphql`
  fragment Contact on STRAPI__COMPONENT_SECTIONS_CONTACT {
    strapi_id
    CONTACTSection: section {
      ...Section
    }
    sectionTitle {
      ...SectionTitle
    }
    contactInfo {
      ...ContactInfo
    }
    button {
      ...Button
    }
    image {
      ...ImageWithConstrainedLayout
    }
    layoutChanger {
      ...LayoutChanger
    }
  }
`
