import React from "react"
import { graphql } from "gatsby"
import Reveal from "../utils/Reveal"

import Section from "../atoms/Section"
import RichText from "../atoms/RichText"
import Box from "../atoms/Box"

const Text = ({ section, richText, TextRichText }) => {
  richText = TextRichText || richText
  return (
    <Section {...section}>
      <Reveal>
        <Box maxWidth={44}>
          <RichText mb={6} isArticle {...richText} section={section} />
        </Box>
      </Reveal>
    </Section>
  )
}

export default Text

export const query = graphql`
  fragment Text on STRAPI__COMPONENT_SECTIONS_TEXT {
    TEXTSection: section {
      ...Section
    }
    TextRichText: richText {
      ...RichText
    }
  }
`
