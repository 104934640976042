import React, { useContext } from "react"
import { ThemeContext } from "styled-components"

import Caret from "../icons/Caret"
import Box from "../atoms/Box"

const SmallArrow = ({ color, variant, direction, height, width, ...rest }) => {
  let currentTheme = useContext(ThemeContext)

  return (
    <Box
      display="flex"
      justifyContent={"center"}
      alignItems={"center"}
      height={height || 3}
      width={width || 3}
      p={1}
      borderRadius="50%"
      boxShadow={
        currentTheme.icons?.carouselControls === "arrowOutlined" ? "sm" : "none"
      }
    >
      <Caret
        width={width ? width - 18 : 30}
        height={height ? height - 18 : 30}
        direction={direction}
        color={color}
        {...rest}
      />
    </Box>
  )
}

export default SmallArrow
