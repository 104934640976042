import React from "react"
import PropTypes from "prop-types"
import { graphql, notGatsby } from "gatsby"

import LayoutChanger from "../atoms/LayoutChanger"
import Html from "../atoms/CustomHtml"
import Section from "../atoms/Section"

import SectionTitle from "../molecules/SectionTitle"
import Box from "../atoms/Box"
import Flex from "../atoms/Flex"
import { Title, Paragraph } from "../atoms/Typography"

const CustomHtml = ({
  section,
  sectionTitle,
  alignHtml,
  integration,
  layoutChanger,
  customHtmlWidth,
}) => {
  const integrationData = integration?.dataObj || integration?.data

  return (
    <Section {...section}>
      <LayoutChanger
        child1Style={
          sectionTitle?.titleMaxWidth === "fullWidth" ? { width: "100%" } : null
        }
        child2Style={{ maxWidth: "100%", maxHeight: "100%" }}
        {...layoutChanger}
      >
        <Box width="100%">
          {sectionTitle && <SectionTitle {...sectionTitle} maxWidth={44} />}
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          flexDirection="column"
          alignItems={customHtmlWidth > 0 ? "center" : "stretch"}
          overflow="hidden"
          width="100%"
          height="100%"
          boxShadow={
            !integrationData?.html && "0px 15px 35px 0px rgba(0,0,0, 15%)"
          }
          maxWidth={!integrationData?.html && "500px"}
        >
          {integrationData?.html ? (
            <Html
              // mb={-5}
              // minWidth="100%"
              display="flex"
              flexDirection="column"
              alignItems={alignHtml === "center" ? "center" : "stretch"}
              html={integrationData.html}
              height="100%"
              width="100%"
              maxWidth={customHtmlWidth > 0 ? customHtmlWidth : "100%"}
              overflow="hidden"
            />
          ) : (
            !integrationData?.html &&
            notGatsby && (
              <Flex
                zIndex={10}
                width="100%"
                height="100%"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                py="85px"
              >
                <Title
                  style={{
                    fontWeight: 500,
                    fontFamily: "dm sans",
                    fontSize: "15px",
                  }}
                  mb="10px"
                >
                  CUSTOM HTML
                </Title>
                <Paragraph
                  fontWeight={100}
                  mb="15px"
                  textAlign="center"
                  px="65px"
                  style={{
                    fontFamily: "dm sans",
                    fontSize: "14px",
                    lineHeight: "22px",
                  }}
                >
                  Create a new custom HTML integration in the Integrations tab
                  and then link it here by clicking "Choose an integration" in
                  the sidebar of this section.
                </Paragraph>
              </Flex>
            )
          )}
        </Box>
      </LayoutChanger>
    </Section>
  )
}

CustomHtml.propTypes = {
  strapi_id: PropTypes.number.isRequired,
  section: PropTypes.shape(Section.strapiProps).isRequired,
  sectionTitle: PropTypes.shape(SectionTitle.strapiProps),
  integration: PropTypes.shape({
    type: PropTypes.oneOf(["customHtml"]),
    data: PropTypes.object,
  }),
}

export default CustomHtml

export const query = graphql`
  fragment CustomHtml on STRAPI__COMPONENT_SECTIONS_CUSTOM_HTML {
    strapi_id
    CUSTOMHTMLSection: section {
      ...Section
    }
    sectionTitle {
      ...SectionTitle
    }
    integration {
      type
      dataObj: data
    }
    layoutChanger {
      ...LayoutChanger
    }
    alignHtml
    customHtmlWidth
  }
`
