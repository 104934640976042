import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import Reveal from "../utils/Reveal"

import Section from "../atoms/Section"
import Flex from "../atoms/Flex"
import Box from "../atoms/Box"
import Button from "../atoms/Button"
import Icon from "../atoms/Icon"
import SectionTitle from "../molecules/SectionTitle"

export const renderIcon = ({ iconAttribute, iconStyles }) => {
  iconAttribute = iconAttribute?.image

  if (!iconAttribute) return null

  const iconSize = iconStyles?.iconSize

  return (
    iconAttribute && (
      <Icon
        flexShrink={0}
        {...iconAttribute}
        mb={4}
        width={
          iconStyles?.iconFit === "height"
            ? "auto"
            : iconStyles?.iconFit === "width" || iconSize
            ? `${iconSize}px`
            : 2
        }
        height={
          iconStyles?.iconFit === "width"
            ? "auto"
            : iconStyles?.iconFit === "height" || iconSize
            ? `${iconSize}px`
            : 2
        }
        maxWidth={
          iconStyles?.iconFit === "width" || iconStyles?.iconFit === "square"
            ? `${iconSize}px`
            : "auto"
        }
        maxHeight={
          iconStyles?.iconFit === "height" || iconStyles?.iconFit === "square"
            ? `${iconSize}px`
            : "auto"
        }
      />
    )
  )
}

const Cta = ({ section, sectionTitle, iconAttribute, iconStyles, button }) => {
  const left = sectionTitle?.align === "left"

  return (
    <Section {...section}>
      <Reveal cascade>
        <Flex
          flexDirection={left ? { _: "column", md: "row" } : "column"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Flex
            width={left ? { _: "100%", md: "60%" } : "100%"}
            flexDirection="column"
            alignItems={sectionTitle?.align === "left" ? "start" : "center"}
          >
            {renderIcon({ iconAttribute, iconStyles })}
            {sectionTitle && (
              <SectionTitle
                {...sectionTitle}
                mr={left ? { _: 0, md: 5 } : "auto"}
              />
            )}
          </Flex>
          {button && (
            <Flex
              mt={left ? { _: 6, md: 0 } : 6}
              alignItems="center"
              justifyContent={left ? { _: "left", md: "flex-end" } : "center"}
              width={left ? { _: "100%", md: "40%" } : "100%"}
            >
              <Button
                aria-label="CTA in section of a page"
                {...button}
                ml={left ? { _: 0, md: 5 } : null}
              />
            </Flex>
          )}
        </Flex>
      </Reveal>
    </Section>
  )
}

Cta.propTypes = {
  strapi_id: PropTypes.number.isRequired,
  section: PropTypes.shape(Section.strapiProps).isRequired,
  sectionTitle: PropTypes.shape(SectionTitle.strapiProps).isRequired,
  button: PropTypes.shape(Button.strapiProps).isRequired,
}

export default Cta

export const query = graphql`
  fragment Cta on STRAPI__COMPONENT_SECTIONS_CTA {
    strapi_id
    CTASection: section {
      ...Section
    }
    sectionTitle {
      ...SectionTitle
    }
    button {
      ...Button
    }
    iconAttribute {
      ...IconAttribute
    }
    iconStyles {
      ...IconStyles
    }
  }
`
