import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import styled from "styled-components"

import Flex from "../atoms/Flex"
import Card from "../atoms/Card"
import { Title } from "../atoms/Typography"
import Icon from "../atoms/Icon"
import RichText from "../atoms/RichText"
import Button from "../atoms/Button"

const StyledCard = styled(Card)`
  transition: opacity 0.3s cubic-bezier(0.17, 0.67, 0.58, 0.84);
  &:hover {
    opacity: ${props => (props.active ? 1 : 0.7)};
  }
`

const InteractiveInfoCard = ({
  idx,
  title,
  icon,
  align = "left",
  card,
  iconLocation,
  iconSize,
  paragraphRichText,
  image,
  active,
  onClick,
  onMouseEnter,
  button,
  showTitle,
  ...rest
}) => {
  const center = align === "center"
  const isIconLeft = iconLocation === "left"

  return (
    <StyledCard
      maxWidth={34}
      height="100%"
      {...rest}
      {...card}
      active={active}
      boxShadow={active ? card?.boxShadow : "none"}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      style={{ cursor: onClick || onMouseEnter ? "pointer" : "default" }}
    >
      <Flex
        px={4}
        py={card?.useCard ? 5 : 4}
        alignItems={center && !isIconLeft ? "center" : null}
        flexDirection={isIconLeft ? "row" : "column"}
      >
        {icon && (
          <Icon
            flexShrink={0}
            {...icon}
            mr={isIconLeft ? 4 : 0}
            mb={isIconLeft ? 0 : 4}
            width={iconSize ? `${iconSize}px` : 2}
            height={iconSize ? `${iconSize}px` : 2}
          />
        )}
        <Flex flexDirection="column" alignItems={center ? "center" : null}>
          {showTitle && (
            <Title textAlign={center ? "center" : null} variant="h3" mb={3}>
              {title}
            </Title>
          )}
          {paragraphRichText?.content && (
            <RichText
              textAlign={center ? "center" : null}
              {...paragraphRichText}
            />
          )}
          {button && (
            <Button aria-label="Button in interactive info card" {...button} />
          )}
        </Flex>
      </Flex>
    </StyledCard>
  )
}

export default InteractiveInfoCard

InteractiveInfoCard.strapiProps = {
  title: PropTypes.string.isRequired,
  paragraph: PropTypes.string,
  icon: PropTypes.shape(Icon.strapiProps),
  paragraphRichText: PropTypes.object,
}

InteractiveInfoCard.propTypes = {
  ...InteractiveInfoCard.strapiProps,
  ...Card.propTypes,
  align: PropTypes.oneOf(["left", "center"]).isRequired,
  iconLocation: PropTypes.oneOf(["left", "top"]),
}

export const query = graphql`
  fragment InteractiveInfoCard on STRAPI__COMPONENT_MOLECULES_INTERACTIVE_INFO_CARD {
    title
    icon {
      ...Icon
    }
    paragraphRichText {
      ...RichText
    }
    button {
      ...Button
    }
    image {
      ...ImageWithConstrainedLayout
    }
  }
`
