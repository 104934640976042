import React, { useState } from "react"
import SwipeableViews from "react-swipeable-views"

import FlexBox from "../atoms/Flex"
import Box from "../atoms/Box"
import Indicator from "../atoms/Indicator"
import Arrow from "../atoms/Arrow"

const SwipeableCarousel = ({ arrows, indicators, children }) => {
  const [activeIndex, setActiveIndex] = useState(0)

  const numChildren = React.Children.count(children)

  const arrowDisplay = arrows ? "block" : "none"

  return (
    <>
      <FlexBox
        alignItems="center"
        justifyContent="center"
        px={{ _: 0, sm: 7, md: 9 }}
        position="relative"
      >
        <Box
          display={{ _: "none", sm: arrowDisplay }}
          position="absolute"
          left={0}
        >
          <Arrow
            left
            disabled={activeIndex === 0}
            onClick={() => setActiveIndex(activeIndex - 1)}
          />
        </Box>
        <SwipeableViews
          index={activeIndex}
          onChangeIndex={idx => setActiveIndex(idx)}
          enableMouseEvents={true}
          style={{ width: "100%" }}
        >
          {children}
        </SwipeableViews>
        <Box display={arrowDisplay} position="absolute" right={0}>
          <Arrow
            disabled={activeIndex === numChildren - 1}
            onClick={() => setActiveIndex(activeIndex + 1)}
          />
        </Box>
      </FlexBox>
      {indicators && (
        <FlexBox justifyContent="center" mt={6}>
          {[...Array(numChildren)].map((_item, idx) => (
            <Box key={idx} px={3}>
              <Indicator
                onClick={() => {
                  setActiveIndex(idx)
                }}
                active={activeIndex === idx}
              />
            </Box>
          ))}
        </FlexBox>
      )}
    </>
  )
}

export default SwipeableCarousel
