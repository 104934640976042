import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import styled from "styled-components"

import Box from "../atoms/Box"
import Flex from "../atoms/Flex"
import { Title } from "../atoms/Typography"
import ThemeSwitcher from "../utils/ThemeSwitcher"

import Checkmark from "../icons/Checkmark"

const PricingGridCategory = ({
  name,
  gridColStyle,
  tiersData,
  startingIndex,
  columns,
  subCategories,
  hasButton,
  sectionTheme,
  lineHeight,
}) => {
  const StyledBox = styled(Box)`
    display: grid;
    grid-template-columns: ${gridColStyle};
    :nth-child(${props => {
          return props.hasButton ? 4 : 3
        }}) {
      border-top: 1px solid rgba(128, 128, 128, 0.25);
    }
    border-left: 1px solid rgba(128, 128, 128, 0.25);
  `
  let counter = startingIndex - 1
  const makeCheckMarks = () => {
    const checkMarks = []
    for (let i = 0; i < columns; i++) {
      if (
        tiersData[i].selectCategory?.[counter]?.["boolean"] ||
        tiersData[i].selectCategory?.[counter]?.["text"]
      ) {
        tiersData[i].selectCategory?.[counter]?.["boolean"] &&
          checkMarks.push(
            <Flex
              style={{ borderRight: "1px solid rgba(128, 128, 128, 0.25)" }}
              height={"100%"}
              justifyContent={"center"}
              alignItems={"center"}
              bg={"background.0"}
            >
              <Checkmark flexShrink="0" />
            </Flex>
          )
        tiersData[i].selectCategory?.[counter]?.["text"] &&
          checkMarks.push(
            <Flex
              style={{ borderRight: "1px solid rgba(128, 128, 128, 0.25)" }}
              height={"100%"}
              justifyContent={"center"}
              alignItems={"center"}
              bg={"background.0"}
            >
              <Title variant={"p"} className="categoryTitle">
                {tiersData[i].selectCategory?.[counter]?.["text"]}
              </Title>
            </Flex>
          )
      } else {
        checkMarks.push(
          <Flex
            style={{ borderRight: "1px solid rgba(128, 128, 128, 0.25)" }}
            height={"100%"}
            justifyContent={"center"}
            bg={"background.0"}
          >
            {/*placeholder div*/}
          </Flex>
        )
      }
    }
    return [checkMarks]
  }
  let highlight = "default"
  const SubCategory = () => (
    <Box
      style={{
        gridColumnStart: 1,
        gridColumnEnd: -1,
      }}
    >
      {subCategories.map((sub, index) => {
        if (subCategories[index].boolean) {
          highlight = sectionTheme !== "highlight" ? "highlight" : "dark"
        } else {
          highlight = "default"
        }
        counter++
        return (
          <Box
            display="grid"
            alignItems="center"
            style={{
              gridColumnStart: 1,
              gridColumnEnd: -1,
              gridTemplateColumns: `${gridColStyle}`,
            }}
          >
            <ThemeSwitcher theme={highlight}>
              <Flex
                bg={"background.0"}
                alignItems={"center"}
                borderRight={"1px solid rgba(128, 128, 128, 0.25)"}
              >
                <Title
                  variant={"p"}
                  lineHeight={lineHeight ? `${lineHeight}em` : "1.5em"}
                  ml={6}
                  className="categoryTitle"
                >
                  {sub.text}
                </Title>
              </Flex>
              {makeCheckMarks()}
            </ThemeSwitcher>
          </Box>
        )
      })}
    </Box>
  )
  //grid lines need divs to create correct borders
  const PlaceholderDivs = () => {
    const divs = []
    for (let i = 0; i < columns; i++) {
      divs.push(
        <Box
          style={{ borderRight: "1px solid rgba(128, 128, 128, 0.25)" }}
          height={"100%"}
          justifyContent={"center"}
          bg={"background.0"}
        >
          {/*placeholder div*/}
        </Box>
      )
    }
    return [divs]
  }
  return (
    <StyledBox hasButton={hasButton} gridColStyle={gridColStyle}>
      <Box
        style={{
          gridColumnStart: 1,
          gridColumnEnd: -1,
        }}
      >
        {name && (
          <Box
            display="grid"
            alignItems="center"
            style={{
              gridColumnStart: 1,
              gridColumnEnd: -1,
              gridTemplateColumns: `${gridColStyle}`,
            }}
          >
            <Title
              style={{ borderRight: "1px solid rgba(128, 128, 128, 0.25)" }}
              variant="h3"
              pt={3}
              pl={4}
              bg={"background.0"}
            >
              {name}
            </Title>
            <PlaceholderDivs />
          </Box>
        )}
      </Box>
      {subCategories && <SubCategory />}
    </StyledBox>
  )
}

export default PricingGridCategory

export const query = graphql`
  fragment PricingGridCategory on STRAPI__COMPONENT_MOLECULES_PRICING_GRID_CATEGORY {
    name
    subCategory {
      ... on STRAPI__COMPONENT_UTILS_TEXT_BOOL {
        text
        boolean
      }
    }
  }
`
