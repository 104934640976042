import React from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"

import Box from "../atoms/Box"
import Flex from "../atoms/Flex"
import SectionTitle from "../molecules/SectionTitle"
import Button from "../atoms/Button"
import Icon from "../atoms/Icon"

const renderIcon = ({ icon, iconStyles }) => {
  icon = icon?.image
  if (!icon) return null

  const iconSize = iconStyles?.iconSize

  return (
    icon && (
      <Icon
        flexShrink={0}
        {...icon}
        mb={4}
        width={
          iconStyles?.iconFit === "height"
            ? "auto"
            : iconStyles?.iconFit === "width" || iconSize
            ? `${iconSize}px`
            : 2
        }
        height={
          iconStyles?.iconFit === "width"
            ? "auto"
            : iconStyles?.iconFit === "height" || iconSize
            ? `${iconSize}px`
            : 2
        }
        maxWidth={
          iconStyles?.iconFit === "width" || iconStyles?.iconFit === "square"
            ? `${iconSize}px`
            : "auto"
        }
        maxHeight={
          iconStyles?.iconFit === "height" || iconStyles?.iconFit === "square"
            ? `${iconSize}px`
            : "auto"
        }
      />
    )
  )
}

const ImageTextCarouselItem = ({
  text,
  button,
  button2,
  textAlign,
  iconStyles,
  icon,
  ...rest
}) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems={textAlign === "center" ? "center" : "start"}
      mx={textAlign === "center" ? "auto" : 0}
      {...rest}
    >
      {renderIcon({ icon, iconStyles })}
      {text && <SectionTitle {...text} align={textAlign} as="p" />}
      {(button || button2) && (
        <Flex justifyContent={textAlign} alignItems="center" mt={4}>
          {button && (
            <Button
              aria-label="Button 1 in carousel slide"
              mr={button2 ? 5 : 0}
              {...button}
            />
          )}
          {button2 && (
            <Button aria-label="Button 2 in carousel slide" {...button2} />
          )}
        </Flex>
      )}
    </Box>
  )
}

export default React.memo(ImageTextCarouselItem)

ImageTextCarouselItem.strapiProps = {
  text: PropTypes.shape(SectionTitle.strapiProps),
  button: PropTypes.shape(Button.strapiProps),
  button2: PropTypes.shape(Button.strapiProps),
  imageOrVideo: PropTypes.object,
  backgroundImage: PropTypes.object,
  backgroundImageMobile: PropTypes.object,
}

export const query = graphql`
  fragment ImageTextCarouselItem on STRAPI__COMPONENT_MOLECULES_IMAGE_TEXT_CAROUSEL_ITEM {
    button {
      ...Button
    }
    button2 {
      ...Button
    }
    icon {
      ...IconAttribute
    }
    text {
      ...SectionTitle
    }
    imageOrVideo {
      ...ImageOrVideo
    }
    backgroundImage {
      ...ImageWithFullWidthLayout
    }
    backgroundImageMobile {
      ...ImageWithFullWidthLayout
    }
  }
`
