import React from "react"
import { graphql } from "gatsby"
import "normalize.css"
import Page from "../components/page/Page"

const PageTemplate = ({ data, pageContext }) => {
  const { masterPageURL } = pageContext
  return <Page data={data} masterPageURL={masterPageURL} />
}

export default PageTemplate

export const query = graphql`
  query PageById($pageId: Int!, $siteId: Int!) {
    strapiPage(strapi_id: { eq: $pageId }) {
      ...Page
    }
    strapiSite(strapi_id: { eq: $siteId }) {
      ...Site
    }
  }
`

// onEnter and preloadQuery functions are meant to improve load time performance
export const preloadQuery = ({ pageId, siteId }) => {
  return [
    {
      query: query,
      variables: {
        pageId,
        siteId,
      },
      prefetch: true, // This option prefetches the query result before it's actually needed.
    },
  ]
}

export const onEnter = ({ variables }) => {
  const { pageId, siteId } = variables
  // This function tells Gatsby to preload the query as soon as the component is about to enter the viewport
  if (typeof window !== "undefined") {
    window.___gatsbyPrefetchQuery(preloadQuery, { pageId, siteId })
  }
}
