import React from "react"
import styled from "styled-components"
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"
import Box from "../atoms/Box"
import Image from "../utils/Image"
import Link from "../utils/Link"

const StyledOverlayImage = styled(Image)`
  opacity: 0;
  transition: opacity 0.3s ease;
  will-change: opacity;
  &:hover {
    opacity: 1;
  }
`

const StyledLink = styled(Link)`
  &:hover {
    & .image {
      ${props =>
        props.overlayImage === null ? "transform: scale(1.05)" : null};
    }
  }
`

const CascadeGrid = ({ children, flexGrid, maxWidth, cascadeGutter }) => {
  // const columnCountObj = constructColumnCountObj(maxWidth, flexGrid.columns)
  return (
    <>
      <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2, 1024: 3 }}>
        <Masonry gutter={cascadeGutter}>
          {children.map((child, i) => {
            return (
              <StyledLink
                optional
                overlayImage={child.overlayImage}
                {...child.link}
              >
                <Box
                  key={i}
                  position="relative"
                  style={{ borderRadius: "5px" }}
                >
                  <Image
                    className="image"
                    alternativeText="Image Tile in Cascade Grid Layout"
                    {...child.image}
                  />
                  {child.overlayImage && (
                    <Box
                      transitionProperty="height, transform"
                      transitionTimingFunction="ease"
                      transitionDuration="md"
                      height="100%"
                    >
                      <StyledOverlayImage
                        {...child.overlayImage}
                        style={{
                          width: "100%",
                          left: 0,
                          top: 0,
                          bottom: 0,
                          position: "absolute",
                          zIndex: 90,
                        }}
                      />
                    </Box>
                  )}
                </Box>
              </StyledLink>
            )
          })}
        </Masonry>
      </ResponsiveMasonry>
    </>
  )
}

// TODO::: use maxWidth and columns to calculate columnsCountBreakPoints object
// FUNCTION IS NOT IMPLEMENTED OR COMPLETE
const constructColumnCountObj = (maxWidth, columns) => {
  const gatsbyBreakPoints = [425, 640, 768, 1024, 1280]
  let breakPoints = {}

  // get max columns
  let cols = []
  Object.keys(columns).map(bp => {
    cols.push(columns[bp])
  })

  const maxCol = Math.max(...cols)
  for (let i = 1; i <= maxCol; i++) {
    if (maxWidth > gatsbyBreakPoints[i - 1]) {
      // all below gatsby breakpoint
      breakPoints[gatsbyBreakPoints[i - 1]] = i
    } else if (maxWidth <= gatsbyBreakPoints[i - 1] && i === maxCol) {
      //
      breakPoints[parseInt(maxWidth)] = i
    }
  }

  return breakPoints
}

export default CascadeGrid
