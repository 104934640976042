import React, { useState } from "react"
import PropTypes from "prop-types"
import { graphql, notGatsby } from "gatsby"
import { useFormik } from "formik"
import { useAlert } from "react-alert"
import * as yup from "yup"

import Section from "../atoms/Section"
import Flex from "../atoms/Flex"
import Button from "../atoms/Button"

import TextField from "../atoms/TextField"
import Form from "../atoms/Form"

import SectionTitle from "../molecules/SectionTitle"

const validationSchema = yup.object({
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
})

const NewsletterSignup = ({
  section,
  sectionTitle,
  layout,
  integration,
  formStyle,
  formWidth,
}) => {
  const alert = useAlert()
  const [loading, setLoading] = useState(false)
  if (formWidth === null || formWidth === undefined) {
    formWidth = "default"
  }
  const formEntryFieldWidth = formWidth === "wide" ? "180px" : "100px"

  const formik = useFormik({
    initialValues: {
      email: "",
      provider: integration?.provider,
      data: integration?.dataObj || integration?.data,
    },
    validationSchema: validationSchema,
    onSubmit: async (values, actions) => {
      if (notGatsby) return

      try {
        setLoading(true)

        const res = await fetch("/.netlify/functions/email-collect", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(values),
        })

        if (!res.ok) {
          const { error } = await res.json()
          alert.show(error, { type: "error" })
        } else {
          alert.show("Your email has been submitted.", { type: "success" })
          actions.resetForm()
        }

        setLoading(false)
      } catch (err) {
        alert.show(err, { type: "error" })
        setLoading(false)
      }
    },
  })

  const horizontal = layout === "horizontal"

  return (
    <Section {...section}>
      <Flex
        alignItems="center"
        justifyContent="space-between"
        flexDirection={horizontal ? { _: "column", md: "row" } : "column"}
      >
        {sectionTitle && (
          <SectionTitle
            pr={horizontal ? { _: null, md: 7, lg: 8 } : null}
            pb={horizontal ? { _: 5, md: null } : 5}
            maxWidth={32}
            {...sectionTitle}
          />
        )}
        <Form
          onSubmit={formik.handleSubmit}
          display="flex"
          flexDirection="row"
          justifyContent={
            horizontal ? { _: "flex-start", md: "flex-end" } : "center"
          }
          alignItems="center"
          width="100%"
        >
          <TextField
            name="email"
            label="Email"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            variant="filled"
            borderBottomRightRadius={formStyle === "default" ? "sm" : 0}
            borderTopRightRadius={formStyle === "default" ? "sm" : 0}
            width="100%"
            maxWidth={26}
            height={3}
            marginRight={formEntryFieldWidth}
          />
          <Button
            aria-label="submit button of a newsletter signup form"
            type="submit"
            variant="primary"
            loading={loading}
            height={3}
            width={{ _: 6, xs: 7, lg: 8 }}
            minWidth={6}
            borderTopRightRadius="sm" // set to the same value as in TextField
            borderBottomRightRadius="sm" // set to the same value as in TextField
            borderTopLeftRadius={formStyle === "default" ? "sm" : 0} // set to the same value as in TextField
            borderBottomLeftRadius={formStyle === "default" ? "sm" : 0} // set to the same value as in TextField
            ml={formStyle === "default" && 4}
          >
            Submit
          </Button>
        </Form>
      </Flex>
    </Section>
  )
}

export default NewsletterSignup

NewsletterSignup.defaultProps = {
  layout: "vertical",
}

NewsletterSignup.propTypes = {
  strapi_id: PropTypes.number.isRequired,
  section: PropTypes.shape(Section.strapiProps).isRequired,
  sectionTitle: PropTypes.shape(SectionTitle.strapiProps).isRequired,
  layout: PropTypes.oneOf(["vertical", "horizontal"]),
}

export const query = graphql`
  fragment NewsletterSignup on STRAPI__COMPONENT_SECTIONS_NEWSLETTER_SIGNUP {
    strapi_id
    NEWSLETTERSIGNUPSection: section {
      ...Section
    }
    sectionTitle {
      ...SectionTitle
    }
    integration {
      provider
      dataObj: data
    }
    layout
    formStyle
    formWidth
  }
`
