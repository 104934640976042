import React from "react"
import Svg from "../utils/Svg"

const Search = ({ iconColor, ...rest }) => {
  return (
    <Svg
      width="19"
      height="20"
      viewBox="0 0 13 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <line
        x1="7.55277"
        y1="8.04684"
        x2="12.3528"
        y2="12.8468"
        stroke={iconColor.includes("#") ? iconColor : "black"}
      />
      <circle
        cx="5"
        cy="5"
        r="4.5"
        stroke={iconColor.includes("#") ? iconColor : "black"}
      />
    </Svg>
  )
}

export default Search
